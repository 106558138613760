// imports
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import useFetch from "use-http"
import { toast } from "react-toastify"
import { useFormState } from "react-use-form-state"

//UI
import {
  Container,
  Form,
  Button,
  Icon,
  Progress,
  Card,
  Message,
} from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
//App
import { useSPTAUser } from "../../../hooks/useSPTAUser"
import { handleUpload } from "../../../services/api"

function PatientMilestoneCreate(props) {
  // props
  const { postUpload, setOpen, patientId, patientServices } = props
  //hooks
  const { t } = useTranslation()
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: [
      ".mov",
      ".MOV",
      ".mp4",
      ".MP4",
      ".jpg",
      ".JPG",
      ".jpeg",
      ".JPEG",
      ".png",
      ".PNG",
    ],
    //accept: 'video/*',
  })
  const { post, response } = useFetch()
  const [SPTAUser] = useSPTAUser()
  const [formState, { text, raw, textarea }] = useFormState()

  //states
  const [progressPct, setProgressPct] = useState({})
  const [hasError, setHasError] = useState(false)

  // local variables

  const createMilestone = async () => {
    if (!formState.values.title) {
      setHasError(t("Please enter a title"))
      return
    }
    if (!formState.values.comments) {
      setHasError(t("Please enter a message "))
      return
    }

    let res = await handleUpload(acceptedFiles, setProgressPct, post, response)
    if (!res.ok) {
      toast.error(
        t("Could not upload file {{filename}}", { filename: res.error })
      )
      return
    }

    if (patientServices.length === 1) {
      formState.setField("service", patientServices[0].id)
    }

    var payload = { ...formState.values }
    let files = res.files
    debugger
    if (files) {
      payload = {
        ...payload,
        user: patientId,
        files,
        isMilestone: true,
        therapists: [SPTAUser.id],
      }
      await post("/userMedias", payload)

      if (response.ok) {
        toast.success(t("Files successfully registered"))
        postUpload()
        setOpen(false)
      } else {
        toast.error(t("Could not register videos "))
        return false
      }
    }
  }

  return (
    <Card className="white-card  progress-photos new-form-card">
      <Card.Content>
        <Card.Header>{formState.values.title || "New milestone"}</Card.Header>

        <Form className="upload-form">
          <Button.Group
            basic
            size="small"
            style={{
              float: "right",
              marginLeft: 5,
            }}
          >
            <Button icon="close" onClick={() => setOpen(false)} />
            <Button icon="save" onClick={() => createMilestone()} />
          </Button.Group>
          {hasError && (
            <Message negative>
              <p>
                {response.data?.message}
                {hasError}
              </p>
            </Message>
          )}
          <Form.Input
            fluid
            label="Title"
            placeholder="Title"
            {...text("title")}
            required
          />{" "}
          {patientServices.length > 1 && (
            <Form.Select
              fluid
              label="Service"
              placeholder="Service"
              {...raw({
                name: "services",
                //onChange: (service, t1) => console.log(service, t1),
              })}
              onChange={(e, { value }) => formState.setField("service", value)}
              options={patientServices.map((x) => ({
                text: x.name,
                value: x.id,
              }))}
              value={formState.values.service}
              required
            />
          )}
          <Form.TextArea
            label="Comments"
            placeholder="Comments"
            {...textarea("comments")}
            required
            rows={5}
          />
          <Container textAlign="center">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Icon name="cloud upload" size="massive" />
              <p>{t("Click to select your files")}</p>
            </div>
            <ul style={{ listStyleType: "none" }}>
              {acceptedFiles.map((file, i) => {
                let filekey = file.name.toLowerCase().split(".").join("")
                let pct = progressPct[filekey]
                return (
                  <li key={file.path}>
                    {file.path} - {file.size} bytes
                    {pct && (
                      <Fragment>
                        <div>{pct + "%"}</div>
                        <Progress percent={pct} size="tiny" />
                      </Fragment>
                    )}
                  </li>
                )
              })}
            </ul>
          </Container>
        </Form>
      </Card.Content>
    </Card>
  )
}
export default PatientMilestoneCreate
