import { useState } from 'react'
import useLocalStorage from 'react-use-localstorage'

export function useLogout() {
  const [SPTAToken] = useLocalStorage('SPTAToken')
  const [, setIsLogedIn] = useState(!!SPTAToken)
  const logout = () => {
    localStorage.removeItem('SPTAToken')
    localStorage.removeItem('SPTAUser')
    setIsLogedIn(false)
  }
  return logout
}
export default useLogout
