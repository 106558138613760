//imports
import React, { Fragment } from "react"

//UI
import {
  Image,
  Container,
  List,
  Button,
  Dimmer,
  Loader,
  Pagination,
  Popup,
} from "semantic-ui-react"
import { youTubeGetID } from "../../../services/api"

function PatientProtocolAllExercises(props) {
  //props
  const {
    exercises,
    exercisesCount,
    exercicesLoading,
    addExercises,
    page,
    setPage,
    limit,
  } = props
  const handlePaginationChange = (e, { activePage }) => setPage(activePage)
  return (
    <Fragment>
      <List divided verticalAlign="middle">
        <Dimmer active={exercicesLoading} inverted>
          <Loader inverted />
        </Dimmer>
        {exercises?.map((exercise) => (
          <List.Item key={exercise.id}>
            <List.Content floated="left">
              <Button
                className="app-button circular"
                icon="chevron left"
                onClick={() => addExercises(exercise)}
              />
            </List.Content>

            <List.Content>
              <Popup
                trigger={
                  <List.Header>
                    {exercise.name}
                    <span style={{ fontWeight: "normal" }}>
                      ( {exercise.category?.join(", ")})
                    </span>
                  </List.Header>
                }
                hoverable
              >
                <Image
                  src={`//img.youtube.com/vi/${youTubeGetID(
                    exercise.url
                  )}/0.jpg `}
                />
              </Popup>
              <List.Description className="rep-scheme">
                {exercise.repScheme}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
      {exercisesCount > limit && (
        <Container textAlign="center">
          <Pagination
            activePage={page}
            onPageChange={handlePaginationChange}
            totalPages={Math.ceil(exercisesCount / limit)}
            boundaryRange={0}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
          />
        </Container>
      )}
    </Fragment>
  )
}
export default PatientProtocolAllExercises
