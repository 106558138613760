import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import ReactCardFlip from "react-card-flip"

import { useFormState } from "react-use-form-state"
import { Button, Card, Confirm, Form, Image, Message } from "semantic-ui-react"
import useFetch from "use-http"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
const genderArray = [
  {
    value: "F",
    text: "female",
  },
  {
    value: "M",
    text: "male",
  },
  {
    value: "O",
    text: "other",
  },
]

export function PatientInformations({ patient, t, setPatient }) {
  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ]

  const [isEdited, setIsEdited] = useState(false)
  const [formState, { text, email, number, select }] = useFormState()
  const { put, response, loading } = useFetch()
  const [appError, setAppError] = useState()
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    ;["firstname", "lastname", "email", "gender", "birthdate"].forEach((x) => {
      formState.setField(x, patient[x])
    })

    let birthYear
    let birthMonth
    let birthDay
    let birth
    const { birthdate } = patient

    if (birthdate) {
      birth = dayjs(birthdate)
      birthYear = birth.year()
      birthMonth = birth.month()
      birthDay = birth.date()
    }

    formState.setField("birthYear", birthYear)
    formState.setField("birthMonth", birthMonth)
    formState.setField("birthDay", birthDay)
  }, [formState, patient])

  const updatePatientInformations = async () => {
    let birthdate = dayjs([
      parseInt(formState.values.birthYear),
      parseInt(formState.values.birthMonth) + 1,
      parseInt(formState.values.birthDay),
    ]).startOf("day")

    var payload = {
      ...formState.values,
      birthdate,
    }

    const updatedUser = await put(`/users/${patient.id}`, payload)
    if (response.ok) {
      setPatient(updatedUser)
      setIsEdited(false)
      setAppError(null)
      toast.success(t("Patient informations succesfully saved"))
    } else {
      toast.error(t("Patient informations could not be saved"))
      setAppError(response.data)
    }
  }

  const history = useHistory()

  const askDeletion = (toDelete) => {
    setConfirmOpen(true)
  }
  const confirmDeletion = async () => {
    await put(`/users/${patient.id}/archive`)
    if (response.ok) {
      history.push("/pt")
    }

    setConfirmOpen(false)
  }
  const cancelDeletion = () => {
    setConfirmOpen(false)
  }
  return (
    <Card fluid>
      <ReactCardFlip isFlipped={isEdited} flipDirection="vertical">
        <Card fluid>
          <Card.Content>
            <>
              <Button
                basic
                icon="edit"
                onClick={() => setIsEdited(!isEdited)}
                floated="right"
              />
              <Image floated="left" size="tiny" src={patient.picture} />
              <Card.Header>
                {patient.firstname} {patient.lastname}{" "}
              </Card.Header>
              <Card.Meta>{patient.email}</Card.Meta>
              {patient.gender && (
                <Card.Meta>
                  {t(genderArray.find((x) => x.value === patient.gender)?.text)}
                </Card.Meta>
              )}
              {patient.birthdate && (
                <Card.Meta>
                  {t("{{age}} years old", {
                    age: dayjs().diff(patient.birthdate, "years"),
                  })}
                </Card.Meta>
              )}
            </>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Content>
            <style>
              {`
#__lpform_firstname_icon{display:none;}

`}
            </style>
            <>
              <Button.Group basic size="small" floated="right">
                <Button icon="save" onClick={updatePatientInformations} />
                <Button icon="close" onClick={() => setIsEdited(false)} />
              </Button.Group>
              <Form
                style={{ clear: "both" }}
                size="large"
                onSubmit={updatePatientInformations}
              >
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Firstname"
                    placeholder="Firstname"
                    {...text("firstname")}
                    required
                  />{" "}
                  <Form.Input
                    fluid
                    label="Lastname"
                    placeholder="Lastname"
                    {...text("lastname")}
                    required
                  />
                </Form.Group>
                <Form.Input
                  fluid
                  label="E-mail address"
                  placeholder="E-mail"
                  {...email("email")}
                  required
                />
                <Form.Group unstackable widths={3}>
                  <Form.Input
                    placeholder={t("year") + " (ex : 1999)"}
                    {...number("birthYear")}
                    required
                    loading={loading}
                    style={{ paddingLeft: 0 }}
                  />
                  <Form.Field>
                    <select {...select("birthMonth")}>
                      {months.map((x, i) => (
                        <option key={x} value={i}>
                          {x}
                        </option>
                      ))}
                    </select>
                  </Form.Field>

                  <Form.Input
                    placeholder={t("day")}
                    {...number("birthDay")}
                    required
                    loading={loading}
                  />
                </Form.Group>
              </Form>
              <div style={{ textAlign: "center" }}>
                <Button
                  size="small"
                  icon="trash"
                  color="red"
                  className="top-15"
                  onClick={() => askDeletion()}
                >
                  Delete this patient
                </Button>
              </div>
              {appError && (
                <Message negative>
                  <Message.Header>{t("Error")}</Message.Header>
                  <p>{appError}</p>
                </Message>
              )}{" "}
            </>
          </Card.Content>
        </Card>
      </ReactCardFlip>
      <Confirm
        size="mini"
        open={confirmOpen}
        onCancel={() => cancelDeletion()}
        onConfirm={() => confirmDeletion()}
      />
    </Card>
  )
}
