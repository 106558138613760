//imports
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFormState } from "react-use-form-state"
import useFetch from "use-http"

//UI
import { Button, Form, Modal, Segment, Message } from "semantic-ui-react"

const Exercise = (props) => {
  //props
  let { exercise: currentExercise } = props

  //hooks
  const { t } = useTranslation()
  const [formState, { text, textarea, raw }] = useFormState(currentExercise)
  const { put, post, response, error } = useFetch()

  //state
  const [categories, setCategories] = useState(props.categories)
  const [hasError, setHasError] = useState(false)

  if (!currentExercise.service) {
    currentExercise.service = ""
  }
  if (!currentExercise?.category) {
    currentExercise.category = []
  }

  const handleAddition = (e, { value }) => {
    setCategories([{ text: value, value }, ...categories])
  }
  const save = async () => {
    if (!formState.values.name) {
      setHasError(t("Name is mandatory"))
      return
    }
    if (!formState.values.service) {
      setHasError(t("Service is mandatory"))
      return
    }

    var payload = {
      ...formState.values,
      service: formState.values.service,
      category: formState.values.category,
    }
    if (!currentExercise?.id) {
      await post("/exercises", payload)
    } else {
      await put("/exercises/" + currentExercise.id, payload)
    }
    if (response.ok) {
      props.refresh()
    }
  }

  return (
    <Fragment>
      <Form size="large" onSubmit={save}>
        <Segment basic>
          <Form.Input
            fluid
            label="Name"
            placeholder="Name"
            {...text("name")}
            required
          />{" "}
          <Form.Input
            fluid
            label="Video url"
            placeholder="Video url"
            {...text("url")}
          />
          <Form.Select
            fluid
            label="Service"
            placeholder="Service"
            {...raw({
              name: "service",
              /*  validate: (value, values, event) => {
                if (!value.trim()) {
                  return t('Service')
                }
              },*/
            })}
            onChange={(e, { value }) => formState.setField("service", value)}
            options={props.services}
            multiple
            value={formState.values.service}
            required
          />
          <Form.Select
            fluid
            label="Categories"
            placeholder="Categories"
            {...raw({
              name: "category",
            })}
            onChange={(e, { value }) => formState.setField("category", value)}
            options={categories}
            multiple
            allowAdditions
            search
            onAddItem={handleAddition}
            value={formState.values.category}
          />
          <Form.Input
            fluid
            label="Description"
            placeholder="Description"
            {...textarea("description")}
          />
          <Form.Input
            fluid
            label="Prescription"
            placeholder="Prescription"
            {...textarea("prescription")}
          />
          {(error || hasError) && (
            <Message negative>
              <p>
                {response.data?.message}
                {hasError}
              </p>
            </Message>
          )}
        </Segment>
      </Form>
      <Modal.Actions>
        <Button
          circular
          icon="close"
          className={"bottom-10 app-button"}
          onClick={() => props.setOpen(false)}
        />

        <Button
          circular
          icon="save"
          className={"bottom-10 app-button"}
          onClick={save}
        />
      </Modal.Actions>
    </Fragment>
  )
}

export default Exercise
