//imports
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useFetch from "use-http"
import { Fade } from "react-awesome-reveal"

//UI
import { Container, Header, Icon, Segment } from "semantic-ui-react"

//App
import MessagesList from "../shared/MessagesList"
import { useSPTAUser } from "../../hooks/useSPTAUser"
import SPTAMessage from "../shared/Message"

function UserMessages(props) {
  // props
  const messagesLimit = 30
  //hooks
  const { t } = useTranslation()
  const { subject, service } = useParams()
  const { get, response } = useFetch()
  const [SPTAUser] = useSPTAUser()

  //states
  const [messages, setMessages] = useState([])
  const [messagesPage, setMessagesPage] = useState(1)
  const [messagesCount, setMessagesCount] = useState([])
  let [messagesLoading, setMessagesLoading] = useState()
  const [isNew, setIsNew] = useState(false)

  //use effect functions
  const fetchMessages = useCallback(async () => {
    setMessagesLoading(true)
    const res = await get(
      `/messages?patient=${SPTAUser.id}&sort=-createdAt&limit=${messagesLimit}&page=${messagesPage}`
    )

    const { count, rows } = res
    if (response.ok) {
      setMessages([...messages, ...rows])
      setMessagesCount(count)
      setMessagesLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*messages,*/ messagesPage, SPTAUser.id, get, response])

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  //local functions
  const nextMessages = () => {
    setMessagesPage(messagesPage + 1)
  }
  const updateMesages = (msg) => {
    setMessages([msg, ...messages])
    setMessagesCount(messagesCount + 1)
    setIsNew(false)
  }
  useEffect(() => {
    if (subject) setIsNew(true)
  }, [subject])
  return (
    <div className={`page user-messages service-${service}`}>
      <Container>
        <Header as="h2">
          {t("Messages")}

          <Icon
            name={!isNew ? "plus circle" : "times circle"}
            style={{ position: "absolute", right: 10, fontSize: "1.2em" }}
            onClick={() => setIsNew(!isNew)}
          ></Icon>
        </Header>
        {isNew && (
          <Segment>
            <Fade>
              <SPTAMessage
                updateMesages={updateMesages}
                therapistSelector={true}
                userTherapists={SPTAUser.therapists.map((x) => x.therapist)}
                subject={subject}
              />
            </Fade>
          </Segment>
        )}
        <MessagesList
          messages={messages}
          nextMessages={nextMessages}
          showMore={messagesLimit * messagesPage < messagesCount}
          messagesLoading={messagesLoading}
        />
      </Container>
    </div>
  )
}
export default UserMessages
