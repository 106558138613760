//imports
import React, { useCallback, useEffect, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useFetch from "use-http"

//UI
import {
  Card,
  Container,
  Dimmer,
  Header,
  Image,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

//App
import { useSPTAUser } from "../../hooks/useSPTAUser"
import { isImage, isVideo } from "../../services/api"

function UserMilestones() {
  // props

  //hooks
  const { t } = useTranslation()
  const params = useParams()
  const { get, response, loading } = useFetch()
  const [SPTAUser] = useSPTAUser()

  //states
  const [open, setOpen] = useState(false)
  const [carouselImages, setCarouselImages] = useState()
  const [milestones, setMilestones] = useState()
  const [, setMilestonesCount] = useState()

  // local variables
  let { service } = params

  //use effect functions
  const fetchMilestones = useCallback(async () => {
    const res = await get(
      `/userMedias?patient=${SPTAUser.id}&service=${service}&isMilestone=true&sort=-createdAt`
    )

    const { count, rows } = res
    if (response.ok) {
      setMilestones(rows)
      setMilestonesCount(count)
    }
  }, [service, SPTAUser.id, get, response])

  useEffect(() => {
    fetchMilestones()
  }, [fetchMilestones])
  //local functions

  //local functions

  const feedCarousel = ({ images, selectedItem }) => {
    setCarouselImages({
      images: images.map(function (image, i) {
        return (
          <div key={image}>
            <img src={image} alt="" />
          </div>
        )
      }),
      selectedItem,
    })
  }

  return (
    <div className={`page progress-cards service-${service}`}>
      <Container>
        <Header as="h2"> {t("Milestones")}</Header>
        <Segment>
          {" "}
          <Dimmer active={loading} inverted>
            <Loader inverted />
          </Dimmer>
          {milestones?.length === 0 && !loading && (
            <Segment>{t("No milestone to display yet...")}</Segment>
          )}
          {milestones?.map((x, j) => (
            <Card fluid className="white-card  progress-photos" key={j}>
              <Card.Content style={{ paddingBottom: 0 }}>
                <Card.Header>{x.title}</Card.Header>
                <Card.Meta>
                  <span className="date">
                    {dayjs(x.createdAt).format("ll")}
                  </span>
                </Card.Meta>

                <Card.Description>{x.comments}</Card.Description>
              </Card.Content>
              <Card.Content>
                <Image.Group className="top-20">
                  {x.files
                    .filter((x) => isImage(x))
                    .map((img, i) => (
                      <Image
                        key={i}
                        className="progress-photo"
                        src={img}
                        onClick={() => {
                          feedCarousel({
                            images: x.files.filter((x) => isImage(x)),
                            selectedItem: i,
                          })
                          setOpen(true)
                        }}
                      />
                    ))}
                  {/*
                <Button
                  circular
                  icon={'add'}
                  size="tiny"
                  className="bottom-10 left-10 app-button add-media"
                  onClick={() => {}}
                />
								 */}
                </Image.Group>
              </Card.Content>

              <Card.Content>
                {x.files
                  .filter((x) => isVideo(x))
                  .map((v, i) => (
                    <div className="video" key={i}>
                      <video
                        id="v0"
                        autobuffer="true"
                        preload="true"
                        controls
                        playsInline
                        poster={"/video-poster.png"}
                        muted
                        loop
                        className="video-player"
                      >
                        <source
                          //  type="video/mp4"
                          src={v + "#t=0.1"}
                        />
                      </video>
                    </div>
                  ))}
                {/*
              <Button
                circular
                icon={'add'}
                size="tiny"
                className="bottom-10 left-10 app-button add-media"
                onClick={() => {}}
              />
								 */}
              </Card.Content>
            </Card>
          ))}
        </Segment>{" "}
      </Container>

      <Modal
        basic
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        className="carousel"
      >
        <Modal.Content>
          <Carousel
            showArrows={true}
            showThumbs={false}
            dynamicHeight={true}
            emulateTouch={true}
            selectedItem={carouselImages?.selectedItem}
            showStatus={false}
          >
            {carouselImages?.images}
          </Carousel>
        </Modal.Content>
      </Modal>
    </div>
  )
}
export default UserMilestones
// '//player.vimeo.com/video/464170025?title=0&byline=0&portrait=0&sidedock=0'
