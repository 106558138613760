//imports
import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useFormState } from "react-use-form-state"
import { useParams, Link } from "react-router-dom"
import useFetch from "use-http"
//UI
import {
  Button,
  Form,
  Grid,
  Segment,
  Container,
  Message,
} from "semantic-ui-react"

//App
import Loading from "../shared/Loading"

const PasswordForgot = (props) => {
  // local variables

  // props

  //hooks
  const { t } = useTranslation()
  const { post, get, put, response, loading, error } = useFetch()

  const [formState, { email, password }] = useFormState({
    services: "",
  })
  let { token } = useParams()

  //states
  const [emailSent, setEmailSent] = useState(false)
  const [tokenChecked, setTokenChecked] = useState(false)
  const [resetDone, setResetDone] = useState(false)
  const [customError, setCustomError] = useState(false)

  //use effect functions

  const validateToken = useCallback(async () => {
    if (!token) return
    await get(`/password-resets/${token}`)
    if (response.ok) {
      setTokenChecked(true)
    } else {
      setCustomError("Invalid link :-/")
    }
  }, [get, response, token])
  useEffect(() => {
    validateToken()
  }, [validateToken])

  //local functions
  const sendPasswordReset = async () => {
    var payload = {
      ...formState.values,
      link: window.location.protocol + "//" + window.location.host + "/forgot",
    }
    await post("/password-resets", payload)
    if (response.ok) {
      setEmailSent(true)
    }
  }

  const changePassword = async () => {
    await put(`/password-resets/${token}/`, formState.values)

    if (response.ok) {
      setEmailSent(false)
      setTokenChecked(false)
      setResetDone(true)
      setCustomError(null)
    }
  }

  useEffect(() => {
    const { password, password_confirm } = formState.values
    if (password !== password_confirm) {
      formState.setFieldError("password_confirm", t("passwords must match"))
    }
  }, [
    formState,
    formState.values.password,
    formState.values.password_confirm,
    t,
  ])

  return (
    <div className={" page login"}>
      <Container fluid style={{ padding: "1em" }}>
        <Grid textAlign="center" style={{ height: "80vh" }}>
          <Grid.Column style={{ maxWidth: 350 }}>
            {!emailSent && !tokenChecked && !resetDone && (
              <Form size="large" onSubmit={sendPasswordReset}>
                <Segment stacked>
                  <>
                    <Form.Input
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder="E-mail address"
                      {...email("email")}
                      required
                    />
                  </>

                  <Button
                    type="submit"
                    fluid
                    size="large"
                    className="app-button"
                  >
                    {loading ? <Loading /> : t("Reset")}
                  </Button>
                </Segment>
              </Form>
            )}
            {emailSent && (
              <Message positive icon className="forgot">
                <Message.Content>
                  {t(
                    `A link to reset your password has been sent to {{email}}.
										 Check your spam folder.`,
                    {
                      email: formState.values.email,
                    }
                  )}
                </Message.Content>
              </Message>
            )}

            {tokenChecked && (
              <Form size="large" onSubmit={changePassword}>
                <Segment stacked>
                  <>
                    <Form.Input
                      fluid
                      label="Password"
                      placeholder="Password"
                      type="password"
                      minLength="8"
                      {...password("password")}
                      required
                    />
                    <Form.Input
                      fluid
                      placeholder="Password confirm"
                      type="password"
                      minLength="8"
                      {...password("password_confirm")}
                      required
                      error={
                        formState.values.password !==
                        formState.values.password_confirm
                          ? true
                          : false
                      }
                    />
                  </>

                  <Button
                    type="submit"
                    fluid
                    size="large"
                    className="app-button"
                  >
                    {loading ? <Loading /> : t("Send")}
                  </Button>
                </Segment>
              </Form>
            )}
            {resetDone && (
              <>
                <Message positive icon className="forgot">
                  <Message.Content>
                    {t(
                      `Your password has been changed.
		You can use it to login.`
                    )}
                  </Message.Content>
                </Message>

                <Button
                  as={Link}
                  to="/login"
                  type="submit"
                  fluid
                  size="large"
                  className="app-button"
                >
                  {loading ? <Loading /> : t("Sign in")}
                </Button>
              </>
            )}
            {error && (
              <Message negative>
                <p>{customError || response.data?.message || response.data}</p>
              </Message>
            )}

            <Message>
              {t("Already registered?")} <Link to="/login">{t("Sign in")}</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  )
}

export default PasswordForgot
