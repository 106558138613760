//imports
import React, { Fragment, useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"
import { useFormState } from "react-use-form-state"
import { useParams } from "react-router-dom"

//UI
import {
  Grid,
  Card,
  Button,
  Icon,
  Message,
  Segment,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react"
import ReactCardFlip from "react-card-flip"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//App
import PatientProtocolAllExercises from "./PatientProtocolAllExercises"
import PatientProtocolAllProtocols from "./PatientProtocolAllProtocols"
import PatientProtocolChosenExercises from "./PatientProtocolChosenExercises"
import PatientProtocolTemplateEdit from "./PatientProtocolTemplateEdit"
import { toast } from "react-toastify"
function PatientProtocol(props) {
  // props
  const { currentProtocol, removeProtocol, patient, expandBlocAndResetIsNew } =
    props

  //hooks
  const { t } = useTranslation()
  const { put, get, post, response, loading, error } = useFetch()
  const [formState, { text, textarea, raw }] = useFormState({
    ...currentProtocol,
    ...{
      service: currentProtocol.service
        ? currentProtocol.service
        : patient?.services[0],
    },
    startDate: currentProtocol.startDate
      ? new Date(currentProtocol.startDate)
      : new Date(),
    endDate: currentProtocol.endDate ? new Date(currentProtocol.endDate) : null,
  })
  //states
  const [isEdited, setIsEdited] = useState()
  const [isProtocolsList, setIsProtocolsList] = useState(false)
  const [protocols, setProtocols] = useState([])
  const [, setProtocolsCount] = useState()
  const [chosenExercises, setChosenExercises] = useState(
    currentProtocol?.exercises || []
  )
  const [exercises, setExercises] = useState([])
  const [exercisesCount, setExercisesCount] = useState()
  const [services, setServices] = useState([])
  const [patientServices, setPatientServices] = useState([])
  const [serviceFilter, setServiceFilter] = useState("")
  const [, setCategories] = useState([])
  const [categoryFilter] = useState("-")
  const [needle, setNeedle] = useState("")
  const [exercicesLoading, setExercicesLoading] = useState(false)
  const [protocolsLoading] = useState(false)
  const [containsFilter, setContainsFilter] = useState()
  const [prescriptionOverrides, setPrescriptionOverrides] = useState(
    currentProtocol?.prescriptionOverrides || []
  )
  const [page, setPage] = useState(1)
  const [hasError, setHasError] = useState(false)
  const limit = 30
  const params = useParams()
  const [open, setOpen] = useState(false)

  //useEffect functions
  const fetchExercisesOrProtocols = useCallback(async () => {
    setExercicesLoading(true)

    let req
    if (isProtocolsList) {
      req =
        `/protocols?sort=name&expand=exercises&template=true&page=${page}&limit=${limit}` +
        (serviceFilter ? `&service=${serviceFilter}` : "") +
        (categoryFilter !== "-" ? `&category=${categoryFilter}` : "") +
        (containsFilter ? "&contains=" + containsFilter : "")
    } else {
      req =
        `/exercises?sort=name&page=${page}&limit=${limit}` +
        (serviceFilter ? `&service=${serviceFilter}` : "") +
        (categoryFilter !== "-" ? `&category=${categoryFilter}` : "") +
        (containsFilter ? "&contains=" + containsFilter : "")
    }
    const res = await get(req)
    const { count, rows } = res
    if (response.ok) {
      if (isProtocolsList) {
        setExercicesLoading(false)
        //remove chosen exercises from list of exercises

        setProtocols(rows)
        setProtocolsCount(count)
      } else {
        setExercicesLoading(false)
        //remove chosen exercises from list of exercises
        let chosenExercisesId = chosenExercises.map((x) => x.id.toString())
        let tmpExercices = rows.filter(
          (x) => !chosenExercisesId.includes(x.id.toString())
        )
        setExercises(tmpExercices)
        setExercisesCount(count)
      }
    }
  }, [
    isProtocolsList,
    serviceFilter,
    categoryFilter,
    containsFilter,
    chosenExercises,
    page,
    get,
    response,
  ])

  useEffect(() => {
    fetchExercisesOrProtocols()
  }, [fetchExercisesOrProtocols])

  const fetchServices = useCallback(async () => {
    const res = await get("/services?sort=name")
    const { rows } = res
    if (response.ok) {
      let servicesOptions = []
      let patientServicesOptions = []
      rows.forEach((element) => {
        servicesOptions.push({
          text: element.name,
          value: element.id,
        })
        if (patient?.services.includes(element.id)) {
          patientServicesOptions.push({
            text: element.name,
            value: element.id,
          })
        }
      })

      setServices(servicesOptions)
      setPatientServices(patientServicesOptions)
    }
  }, [patient?.services, get, response, setServices])

  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  const fetchCategories = useCallback(async () => {
    const categoriesRes = await get(
      `/exercises/categories?` +
        (serviceFilter ? `&service=${serviceFilter}` : "")
    )
    if (response.ok) {
      let categoriesOptions = []
      categoriesRes.forEach((element) => {
        if (element) {
          categoriesOptions.push({
            text: element,
            value: element,
          })
        }
      })
      setCategories(categoriesOptions)
    }
  }, [get, serviceFilter, response])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  //local functions

  const addExercises = (ex) => {
    console.log("adding exercises")
    if (!Array.isArray(ex)) {
      ex = [ex]
    }
    let tmpExercices = exercises
    let tmpChosenExercises = chosenExercises
    ex.forEach((exercice) => {
      tmpExercices = tmpExercices.filter(
        (x) => x.id.toString() !== exercice.id.toString()
      )
      if (
        tmpChosenExercises.findIndex(
          (el) => el.id.toString() === exercice.id.toString()
        ) === -1
      ) {
        tmpChosenExercises.push(exercice)
      }
    })
    setExercises(tmpExercices)
    setChosenExercises(tmpChosenExercises)
  }

  const removeExercise = (ex) => {
    let tmpChosenExercises = chosenExercises.filter(
      (x) => x.id.toString() !== ex.id.toString()
    )
    setChosenExercises(tmpChosenExercises)
    let tmpExercices = exercises
    tmpExercices.push(ex)
    tmpExercices.sort((a, b) => (a.name > b.name ? 1 : -1))
    setExercises(tmpExercices)

    let overrides = prescriptionOverrides.filter((x) => {
      return x.exerciseId.toString() !== ex.id.toString()
    })
    setPrescriptionOverrides(overrides)
  }

  const serviceChange = (e, data) => {
    setServiceFilter(data.value)
  }

  const handleSearchChange = (e, { value }) => {
    if (!value) {
      setNeedle(null)
      setContainsFilter()
    } else {
      setNeedle(value)
      if (value.length >= 3) {
        setContainsFilter(value)
      }
    }
  }

  const resetSearch = () => {
    setNeedle("")
    setContainsFilter()
  }

  const editItem = (e, data) => {
    let id = data.value
    if (id) {
      let editedExercise = chosenExercises.find((x) => x.id.toString() === id)
      let overrides = prescriptionOverrides
      if (!overrides.find((x) => x.exerciseId === id)) {
        overrides.push({
          exerciseId: id,
          prescription: editedExercise?.prescription,
        })
      }
      setPrescriptionOverrides(overrides)
    }
    // is Edited est null on repasse juste en edition
    setIsEdited(id)
  }

  const toggleExerciceProtocolLists = (_) => {
    setIsProtocolsList(!isProtocolsList)
  }

  const save = async () => {
    if (!formState.values.name) {
      setHasError(t("Name is mandatory"))
      return
    }
    if (!formState.values.service) {
      setHasError(t("Service is mandatory"))
      return
    }
    var payload = {
      ...formState.values,
      exercises: chosenExercises.map((x) => x.id),
      user: params.id,
      prescriptionOverrides,
    }

    if (!currentProtocol?.id) {
      await post("/protocols", payload)
    } else {
      await put("/protocols/" + currentProtocol.id, payload)
    }
    if (response.ok) {
      toast.success(t("Protocol successfully saved"))
      expandBlocAndResetIsNew(false)
    }
  }
  const saveAsTemplate = async (data) => {
    var payload = {
      ...data,
      exercises: chosenExercises.map((x) => x.id),
      user: null,
      prescriptionOverrides,
      isTemplate: true,
    }
    await post("/protocols", payload)
    if (response.ok) {
      setOpen(false)
    }
  }
  const removeTemplateProtocol = (id) => {
    removeProtocol(id)
    setProtocols(protocols.filter((x) => "" + x.id !== "" + id))
  }

  return (
    <Fragment>
      {console.log(formState)}
      <Grid columns={2}>
        <Grid.Column>
          <Card fluid>
            <Dimmer active={loading} inverted>
              <Loader inverted />
            </Dimmer>
            <Card.Content>
              {(chosenExercises.length !== 0 ||
                formState.values.name?.trim() !== "") && (
                <Button.Group
                  basic
                  size="small"
                  style={{
                    float: "right",
                    marginLeft: 5,
                  }}
                >
                  <Button
                    icon="save"
                    content={t("Save")}
                    onClick={() => save()}
                  />
                  <Button
                    icon="save outline"
                    onClick={() => setOpen(!open)}
                    content={t("Save as template")}
                  />
                </Button.Group>
              )}
              <Card.Header>
                {currentProtocol ? currentProtocol.name : t("Exercise session")}
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Description>
                <Form size="large" onSubmit={save}>
                  <Segment basic>
                    <Form.Input
                      label="Name"
                      placeholder="Name"
                      {...text("name")}
                      required
                    />{" "}
                    <Form.Select
                      fluid
                      label="Service"
                      placeholder="Service"
                      {...raw({
                        name: "service",
                        validate: (value, values, event) => {
                          if (!value.trim()) {
                            return t("Service")
                          }
                        },
                      })}
                      onChange={(e, { value }) =>
                        formState.setField("service", value)
                      }
                      options={patientServices}
                      value={formState.values.service}
                      required
                    />
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>{t("Start date")}</label>
                        <DatePicker
                          selected={formState.values.startDate}
                          onChange={(date) =>
                            formState.setField("startDate", date)
                          }
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>{t("End date")}</label>
                        <DatePicker
                          selected={formState.values.endDate}
                          onChange={(date) =>
                            formState.setField("endDate", date)
                          }
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.TextArea
                      label="Comments"
                      placeholder="comments"
                      {...textarea("comments")}
                    />
                    {(error || hasError) && (
                      <Message negative>
                        <p>
                          {response.data?.message}
                          {hasError}
                        </p>
                      </Message>
                    )}
                  </Segment>
                </Form>
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Description>
                <PatientProtocolChosenExercises
                  removeExercise={removeExercise}
                  chosenExercises={chosenExercises}
                  prescriptionOverrides={prescriptionOverrides}
                  setChosenExercises={setChosenExercises}
                  editItem={editItem}
                  isEdited={isEdited}
                />
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Card fluid>
            <Card.Content textAlign="center">
              <Button
                basic={isProtocolsList ? true : false}
                className={isProtocolsList ? "" : "app-button"}
                onClick={toggleExerciceProtocolLists}
              >
                {t("Exercises")}
              </Button>
              <Icon
                flipped={isProtocolsList ? null : "horizontally"}
                name="toggle on"
              />
              <Button
                basic={isProtocolsList ? false : true}
                className={isProtocolsList ? "app-button" : ""}
                onClick={toggleExerciceProtocolLists}
              >
                {t("Template protocols")}
              </Button>
            </Card.Content>

            <Card.Content>
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    placeholder="Search"
                    icon={
                      !needle ? (
                        <Icon name="search" />
                      ) : (
                        <Icon name="close" link onClick={resetSearch} />
                      )
                    }
                    onChange={handleSearchChange}
                    value={needle}
                  />
                  <Form.Select
                    fluid
                    clearable
                    options={services}
                    placeholder="Service"
                    onChange={serviceChange}
                  />
                </Form.Group>
              </Form>
              <ReactCardFlip
                isFlipped={isProtocolsList}
                flipDirection="horizontal"
              >
                <PatientProtocolAllExercises
                  exercises={exercises}
                  exercisesCount={exercisesCount}
                  exercicesLoading={exercicesLoading}
                  addExercises={addExercises}
                  page={page}
                  setPage={setPage}
                  limit={limit}
                />
                <PatientProtocolAllProtocols
                  protocols={protocols}
                  protocolsLoading={protocolsLoading}
                  addExercises={addExercises}
                  removeTemplateProtocol={removeTemplateProtocol}
                />
              </ReactCardFlip>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
      <PatientProtocolTemplateEdit
        setOpen={setOpen}
        open={open}
        currentProtocol={currentProtocol}
        saveAsTemplate={saveAsTemplate}
        services={services}
      />
    </Fragment>
  )
}
export default PatientProtocol
