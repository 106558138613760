//imports
import React, { Fragment, useCallback, useEffect, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"
//UI
import { Card, List, Divider, Icon, Image, Modal } from "semantic-ui-react"
import { Slide } from "react-awesome-reveal"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

//App
import { isImage, isVideo } from "../../../services/api"
import PatientMilestoneCreate from "./PatientMilestoneCreate"
import PatientMilestone from "./PatientMilestone"

function PatientMilestones(props) {
  // local variables

  // props
  const {
    expand,
    expandBloc,
    openModal,
    isNewMilestone: isNew,
    setIsNewMilestone: setIsNew,
    patientId,
    patientServices,
  } = props

  //hooks
  const { t } = useTranslation()
  const { get, response, loading } = useFetch()

  //states
  const [milestones, setMilestones] = useState([])
  const [, setMilestonesCount] = useState([])
  const [open, setOpen] = useState(false)
  const [carouselImages, setCarouselImages] = useState()
  const [isEdited, setIsEdited] = useState()

  //use effect functions
  const fetchMilestones = useCallback(async () => {
    const res = await get(
      `/userMedias?patient=${patientId}&pt=&isMilestone=true&sort=-createdAt`
    )
    const { count, rows } = res || {}
    if (response.ok) {
      setMilestones(rows)
      setMilestonesCount(count)
    }
  }, [patientId, get, response])

  useEffect(() => {
    fetchMilestones()
  }, [fetchMilestones])
  //local functions

  const createNew = () => {
    setIsNew(true)
    expandBloc("milestones")
  }

  const save = () => {
    expandBloc()
    setIsNew(false)
  }

  const cancel = () => {
    expandBloc()
    setIsNew(false)
  }
  const toggleBlocExpand = () => {
    expand ? expandBloc() : expandBloc("milestones")
    setIsNew(false)
  }

  const feedCarousel = ({ images, selectedItem }) => {
    setCarouselImages({
      images: images.map(function (image, i) {
        return (
          <div key={image}>
            <img src={image} alt="" />
          </div>
        )
      }),
      selectedItem,
    })
  }
  return (
    <Fragment>
      <Card fluid className="milestones-card">
        <Card.Content className="header-container">
          <Icon
            onClick={() => toggleBlocExpand()}
            name={expand ? "compress" : "expand"}
            style={{
              float: "right",
              cursor: "pointer",
            }}
          />
          {!isNew && (
            <Icon
              onClick={() => createNew()}
              name={"add"}
              style={{
                float: "right",
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
          )}
          {isNew && (
            <Fragment>
              <Icon
                onClick={() => cancel()}
                name={"close"}
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginRight: "15px",
                }}
              />
              <Icon
                onClick={() => save()}
                name={"save"}
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginRight: "15px",
                }}
              />
            </Fragment>
          )}

          <Card.Header
            onClick={() => toggleBlocExpand()}
            style={{ cursor: "pointer" }}
          >
            {t("Milestones")}
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Card.Description className={!expand ? "card-container" : ""}>
            {expand !== "milestones" ? (
              <List divided selection>
                {milestones?.length === 0 &&
                  !loading &&
                  t("No milestone to display yet...")}
                {milestones.map((x, i) => (
                  <List.Item
                    key={(x, i)}
                    onClick={() =>
                      openModal({
                        header: `${x.title} - ${dayjs(x.createdAt).format(
                          "ll"
                        )}`,
                        content: (
                          <Fragment>
                            {x.comments}
                            <Image.Group className="top-20 progress-photos">
                              {x.files
                                .filter((x) => isImage(x))
                                .map((img, i) => (
                                  <Image
                                    key={i}
                                    className="progress-photo"
                                    src={img}
                                    onClick={() => {
                                      feedCarousel({
                                        images: x.files.filter((x) =>
                                          isImage(x)
                                        ),
                                        selectedItem: i,
                                      })
                                      setOpen(true)
                                    }}
                                  />
                                ))}
                            </Image.Group>
                            <Divider />
                            {x.files
                              .filter((x) => isVideo(x))
                              .map((v, i) => (
                                <div className="video" key={i}>
                                  <video
                                    id="v0"
                                    autobuffer="true"
                                    preload="true"
                                    controls
                                    playsInline
                                    poster={"/video-poster.png"}
                                    muted
                                    loop
                                    className="video-player"
                                  >
                                    <source
                                      //  type="video/mp4"
                                      src={v + "#t=0.1"}
                                    />
                                  </video>
                                </div>
                              ))}
                          </Fragment>
                        ),
                      })
                    }
                  >
                    <List.Header>
                      {x.title} - {dayjs(x.createdAt).format("ll")}{" "}
                    </List.Header>

                    <List.Content className="content">
                      {x.comments}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            ) : (
              <Card.Group>
                {isNew && (
                  <div style={{ overflow: "hidden" }}>
                    <Slide direction="left">
                      <PatientMilestoneCreate
                        setOpen={setIsNew}
                        postUpload={fetchMilestones}
                        patientId={patientId}
                        patientServices={patientServices}
                      />
                    </Slide>
                  </div>
                )}
                {milestones?.length === 0 &&
                  !loading &&
                  t("No milestone to display yet...")}
                {milestones.map((x, j) => (
                  <PatientMilestone
                    milestone={x}
                    isEdited={isEdited === x.id}
                    setIsEdited={setIsEdited}
                    feedCarousel={feedCarousel}
                    postUpdate={fetchMilestones}
                  />
                  /*
                  <Card className="white-card  progress-photos" key={j}>
                    <Card.Content
                      style={{
                        paddingBottom: 0,
                      }}
                    >
                      <Button.Group
                        basic
                        size="small"
                        style={{
                          float: 'right',
                          marginLeft: 5,
                        }}
                      >
                        <Button icon="edit" />
                        <Button icon="trash" />
                      </Button.Group>
                      <Card.Header>

                        {x.title} - {dayjs(x.createdAt).format('ll')}
                      </Card.Header>

                      <Card.Description>{x.comments}</Card.Description>
                    </Card.Content>
                    <Card.Content>
                      <Image.Group className="top-20">
                        {x.files
                          .filter((x) => isImage(x))
                          .map((img, i) => (
                            <Image
                              key={i}
                              src={img}
                              onClick={() => {
                                feedCarousel({
                                  images: x.files.filter((x) => isImage(x)),
                                  selectedItem: i,
                                })
                                setOpen(true)
                              }}
                            />
                          ))}
                      </Image.Group>
                    </Card.Content>

                    <Card.Content>
                      {x.files
                        .filter((x) => isVideo(x))
                        .map((v, i) => (
                          <div className="video" key={i}>
                            <video
                              id="v0"
                              autobuffer="true"
                              preload="true"
                              controls
                              playsInline
                              poster={'/video-poster.png'}
                              muted
                              loop
                              className="video-player"
                            >
                              <source
                                //  type="video/mp4"
                                src={v + '#t=0.1'}
                              />
                            </video>
                          </div>
                        ))}
                    </Card.Content>
                  </Card>
               */
                ))}
              </Card.Group>
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      <Modal
        basic
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        className="carousel"
      >
        <Modal.Content>
          <Carousel
            showArrows={true}
            showThumbs={false}
            dynamicHeight={true}
            emulateTouch={true}
            selectedItem={carouselImages?.selectedItem}
            showStatus={false}
          >
            {carouselImages?.images}
          </Carousel>
        </Modal.Content>
      </Modal>
    </Fragment>
  )
}

export default PatientMilestones
