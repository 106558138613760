// imports
import React, { useState } from "react"
import useFetch from "use-http"
//UI
import { Dimmer, Loader, List, Checkbox, Image, Icon } from "semantic-ui-react"

//App

import { updateSPTAUser } from "../../services/api"
function UserServicesSelector(props) {
  // local variables

  // props
  const {
    user,
    isEdited,
    hidePictures,
    mustUpdateSPTAUser,
    services = [],
  } = props

  //hooks
  const { patch, response, loading } = useFetch()

  //states

  const [updating, setUpdating] = useState()

  //use effect functions

  //local functions
  const toggleTherapist = (serviceId, therapistId) => {
    let userTherapists = user.therapists || []
    if (
      userTherapists?.find(
        (e) =>
          e.therapist.toString() === therapistId.toString() &&
          e.service.toString() === serviceId.toString()
      )
    ) {
      //present, must remove
      let therapists = userTherapists.filter(
        (e) =>
          !(
            e.therapist.toString() === therapistId.toString() &&
            e.service.toString() === serviceId.toString()
          )
      )
      userTherapists = therapists

      if (
        !therapists?.find((e) => e.service.toString() === serviceId.toString())
        //no more therapist in the service remove the service
      ) {
        let services = user.services.filter(
          (e) => !(e.toString() === serviceId.toString())
        )
        user.services = services
      }
    } else {
      //adding
      let serviceTherapist = { therapist: therapistId, service: serviceId }
      //  therapists: [{ therapist: therapistId, service: serviceId }],
      userTherapists.push(serviceTherapist)
      if (!user.services.includes(serviceId)) {
        user.services.push(serviceId)
      }
    }
    setUpdating(therapistId + serviceId)
    updateUserTherapist({
      therapists: userTherapists,
      services: user.services,
    })

    user.therapists = userTherapists
  }

  const isTherapist = (serviceId, therapistId) => {
    let userTherapists = user.therapists || []
    return userTherapists?.find((e) =>
      e.therapist.toString() === therapistId.toString() &&
      e.service.toString() === serviceId.toString()
        ? true
        : false
    )
  }

  const updateUserTherapist = async (serviceTherapist) => {
    /*  await patch('/users/' + user.id, serviceTherapist)
    if (response.ok) {
      // setTherapists(rows)
    }
*/
    const userResponse = await patch("/users/" + user.id, {
      ...serviceTherapist,
    })
    if (response.ok) {
      if (mustUpdateSPTAUser) {
        updateSPTAUser(userResponse)
      }
    } else {
      props.setHasError(true)
    }
  }

  const isInService = (serviceId) => {
    return user?.services?.includes(serviceId) ? true : false
  }

  return (
    <List className="top-20" divided selection style={{ position: "relative" }}>
      <Dimmer active={loading} inverted>
        <Loader inverted />
      </Dimmer>
      {services?.map((service) => (
        <List.Item
          key={service.id}
          className={!isInService(service.id) && !isEdited ? "hidden" : ""}
        >
          <List.Content>
            <List.Header className="bottom-5">
              {isEdited ? (
                <Checkbox
                  label={service.name}
                  //onChange={this.toggle}
                  disabled
                  checked={isInService(service.id)}
                />
              ) : (
                service.name
              )}
            </List.Header>
            <List.Description>
              <List
                size="small"
                horizontal={!isEdited}
                divided
                style={{ paddingTop: 0 }}
              >
                {service?.therapists?.map((therapist) => (
                  <List.Item
                    key={therapist._id || therapist.id + service.id}
                    onClick={() =>
                      toggleTherapist(service.id, therapist._id || therapist.id)
                    }
                    className={
                      !isTherapist(service.id, therapist._id || therapist.id) &&
                      !isEdited
                        ? "hidden"
                        : ""
                    }
                  >
                    <List.Content floated="right">
                      {loading && updating === therapist.id + service.id ? (
                        <Loader active inline size="mini" />
                      ) : (
                        isEdited && (
                          <Icon
                            name={
                              isTherapist(
                                service.id,
                                therapist._id || therapist.id
                              )
                                ? "check circle outline"
                                : "circle outline"
                            }
                            size="large"
                            className="blue-smith"
                          />
                        )
                      )}
                    </List.Content>
                    {!hidePictures && <Image avatar src={therapist.picture} />}
                    <List.Content>
                      <List.Header className="blue-smith">
                        {`${therapist.firstname} ${therapist.lastname}`}
                      </List.Header>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </List.Description>
          </List.Content>
        </List.Item>
      ))}
    </List>
  )
}
export default UserServicesSelector
