//imports
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useFormState } from "react-use-form-state"
import { useHistory, Link } from "react-router-dom"
import useFetch from "use-http"
//UI
import {
  Button,
  Form,
  Grid,
  Segment,
  Container,
  Message,
} from "semantic-ui-react"

//App
import Loading from "../shared/Loading"
import { useSPTAUser } from "../../hooks/useSPTAUser"
import { toast } from "react-toastify"

const PasswordChange = (props) => {
  // local variables

  // props
  const { isEmbedded } = props

  //hooks
  const { t } = useTranslation()
  const { put, response, loading, error } = useFetch()

  //states

  const [formState, { password }] = useFormState({
    services: "",
  })

  const [SPTAUser] = useSPTAUser()
  //use effect functions

  //local functions

  let history = useHistory()

  const changePassword = async () => {
    var payload = {
      ...formState.values,
      hash: btoa(SPTAUser.email + ":" + formState.values.old_password),
    }
    console.log(SPTAUser.email)

    console.log(payload)
    await put(`/users/${SPTAUser.id}/password`, payload)
    if (response.ok) {
      toast.success("Password changed")
      history.push("/")
    }
  }

  useEffect(() => {
    const { password, password_confirm } = formState.values
    if (password !== password_confirm) {
      formState.setFieldError("password_confirm", t("passwords must match"))
    }
  }, [
    formState,
    formState.values.password,
    formState.values.password_confirm,
    t,
  ])

  return (
    <div className={"login " + (isEmbedded && "page")}>
      <Container fluid style={{ padding: "1em" }}>
        <Grid textAlign="center" style={{ height: "80vh" }}>
          <Grid.Column style={{ maxWidth: 350 }}>
            <Form size="large" onSubmit={changePassword}>
              <Segment stacked>
                <>
                  <Form.Input
                    fluid
                    label="Previous Password"
                    placeholder="Password"
                    type="password"
                    minLength="8"
                    {...password("old_password")}
                    required
                  />
                  <Form.Input
                    fluid
                    label="Password"
                    placeholder="Password"
                    type="password"
                    minLength="8"
                    {...password("password")}
                    required
                  />
                  <Form.Input
                    fluid
                    placeholder="Password confirm"
                    type="password"
                    minLength="8"
                    {...password("password_confirm")}
                    required
                    error={
                      formState.values.password !==
                      formState.values.password_confirm
                        ? true
                        : false
                    }
                  />
                </>

                {error && (
                  <Message negative>
                    <p>{response.data?.message || response.data}</p>
                  </Message>
                )}
                <Button type="submit" fluid size="large" className="app-button">
                  {loading ? (
                    <Loading />
                  ) : isEmbedded ? (
                    t("Register patient")
                  ) : (
                    t("Send")
                  )}
                </Button>
              </Segment>
            </Form>
            {!isEmbedded && (
              <Message>
                {t("Already registered?")}{" "}
                <Link to="/login">{t("Sign in")}</Link>
              </Message>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  )
}

export default PasswordChange
