//imports
import React from 'react'
import { useTranslation } from 'react-i18next'

//UI
import { Image, List, Button, Dimmer, Loader } from 'semantic-ui-react'
import logo192 from '../../../images/logo192.png'

function PatientProtocolAllProtocols(props) {
  //props
  const {
    protocols,
    protocolsLoading,
    addExercises,
    removeTemplateProtocol,
  } = props

  //hooks
  const { t } = useTranslation()

  return (
    <List divided verticalAlign="middle">
      <Dimmer active={protocolsLoading} inverted>
        <Loader inverted />
      </Dimmer>
      {protocols.map((x) => (
        <List.Item key={x.id}>
          <Button.Group
            basic
            size="small"
            style={{
              float: 'right',
              marginLeft: 5,
            }}
          >
            <Button icon="trash" onClick={() => removeTemplateProtocol(x.id)} />
          </Button.Group>
          <List.Content floated="left">
            <Button
              className="app-button circular"
              icon="chevron left"
              onClick={() => addExercises(x.exercises)}
            />
          </List.Content>
          <Image avatar src={logo192} />
          <List.Content>
            <List.Header>
              {x.name} ( {x.category?.join(', ')})
            </List.Header>

            <List.Description className="rep-scheme">
              {x.exercises?.map((ex) => ex.name).join(', ')}
            </List.Description>
          </List.Content>
        </List.Item>
      ))}
      {!protocols?.length && (
        <List.Item>{t('Nothing to display yet...')}</List.Item>
      )}
    </List>
  )
}
export default PatientProtocolAllProtocols
