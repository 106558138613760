//imports
import React, { useEffect, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useFetch from "use-http"

//UI
import { Container, Header } from "semantic-ui-react"
import UserProtocolDisplay from "./UserProtocolDisplay"

function UserProtocols() {
  //hooks
  const { t } = useTranslation()
  const { id: protocolId } = useParams()
  const { get, response } = useFetch()

  //states
  const [protocol, setProtocol] = useState()
  const [protocolLoading, setProtocolLoading] = useState()

  //useeffect functions
  const fetchProtocol = useCallback(async () => {
    setProtocolLoading(true)
    const protocol = await get(`/protocols/${protocolId}?expand=exercises`)
    if (response.ok) {
      setProtocol(protocol)
      setProtocolLoading(false)
    }
  }, [protocolId, get, response])

  useEffect(() => {
    fetchProtocol()
  }, [fetchProtocol])

  return (
    <div className={`page user-protocol`}>
      <Container>
        <Header as="h2"> {t("My exercises")}</Header>
        <UserProtocolDisplay
          protocolLoading={protocolLoading}
          protocol={protocol}
        />
      </Container>
    </div>
  )
}
export default UserProtocols
// '//player.vimeo.com/video/464170025?title=0&byline=0&portrait=0&sidedock=0'
