import React, { useState, useEffect, useCallback } from "react"
import { Container, Header, Button, Segment, Form } from "semantic-ui-react"
import useFetch from "use-http"
import { useTranslation } from "react-i18next"

import { useSPTAUser } from "../../hooks/useSPTAUser"
import { useFormState } from "react-use-form-state"
import dayjs from "dayjs"

var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)
function UserProtocols() {
  const [isEdited, setIsEdited] = useState(false)
  const [user, setUser] = useState({})
  const [age, setAge] = useState()
  const { t } = useTranslation()

  const [, updateSPTAUser] = useSPTAUser()
  const { get, put, response, loading } = useFetch()
  const [formState, { text, email, radio, number, select }] = useFormState()
  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ]

  const genderArray = [
    {
      value: "F",
      text: "female",
    },
    {
      value: "M",
      text: "male",
    },
    {
      value: "O",
      text: "other",
    },
  ]

  const getCurrentUser = useCallback(async () => {
    const me = await get("/users/me")
    if (response.ok) {
      setUser(me)
      ;["firstname", "lastname", "email", "gender", "birthdate"].forEach(
        (x) => {
          formState.setField(x, me[x])
        }
      )

      let birthYear
      let birthMonth
      let birthDay
      let birth

      birth = dayjs(me.birthdate)
      if (me.birthdate) {
        birthYear = birth.year()
        birthMonth = birth.month()
        birthDay = birth.date()
        setAge(dayjs().diff(me.birthdate, "years"))
      }
      formState.setField("birthYear", birthYear)
      formState.setField("birthMonth", birthMonth)
      formState.setField("birthDay", birthDay)
    }
  }, [formState, get, response])

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser]) // componentDidMount

  const updateUser = async () => {
    let birthdate = dayjs([
      parseInt(formState.values.birthYear),
      parseInt(formState.values.birthMonth) + 1,
      parseInt(formState.values.birthDay),
    ]).startOf("day")

    var payload = {
      ...formState.values,
      birthdate,
      ...{ services: user.services },
    }
    const userRes = await put("/users/me", payload)
    if (response.ok) {
      updateSPTAUser(userRes)
      setIsEdited(false)
      getCurrentUser()
    }
  }

  return (
    <div className=" page user-profile">
      <Container>
        <Header as="h2"> {t("My profile")}</Header>
        <Segment
          className="dark-grey services-card top-30 white-card"
          style={{ fontSize: "1.2em" }}
        >
          <Form
            style={{ marginTop: "10px" }}
            className={isEdited ? "edit" : "read"}
          >
            <Form.Input
              fluid
              label="Firstname"
              placeholder="Firstname"
              {...text("firstname")}
              required
              readOnly={!isEdited}
              loading={loading}
            />{" "}
            <Form.Input
              fluid
              label="Lastname"
              placeholder="Lastname"
              {...text("lastname")}
              required
              readOnly={!isEdited}
              loading={loading}
            />
            <Form.Input
              fluid
              label="E-mail address"
              placeholder="E-mail"
              {...email("email")}
              required
              readOnly={!isEdited}
              loading={loading}
            />
            <Form.Field>
              <label> {t("Gender")} </label>

              {isEdited ? (
                <Form.Group
                  inline
                  style={{
                    marginTop: "5px",
                    paddingLeft: "1em",
                  }}
                >
                  {genderArray.map((x) => (
                    <Form.Field>
                      <input {...radio("gender", x.value)} />
                      <label>{t(x.text)}</label>
                    </Form.Field>
                  ))}
                </Form.Group>
              ) : (
                <Form.Input
                  fluid
                  value={t(
                    genderArray.find((x) => x.value === user.gender)?.text
                  )}
                  readOnly={true}
                  loading={loading}
                />
              )}
            </Form.Field>
            <Form.Field>
              <label> {isEdited ? t("Birth date") : t("Age")} </label>

              {isEdited ? (
                <Form.Group unstackable widths={3}>
                  <Form.Input
                    placeholder={t("year") + " (ex : 1999)"}
                    {...number("birthYear")}
                    required
                    readOnly={!isEdited}
                    loading={loading}
                  />
                  <Form.Field>
                    <select {...select("birthMonth")}>
                      {months.map((x, i) => (
                        <option value={i}>{x}</option>
                      ))}
                    </select>
                  </Form.Field>

                  <Form.Input
                    placeholder={t("day")}
                    {...number("birthDay")}
                    required
                    readOnly={!isEdited}
                    loading={loading}
                  />
                </Form.Group>
              ) : (
                <Form.Input
                  fluid
                  value={age}
                  readOnly={true}
                  loading={loading}
                />
              )}
            </Form.Field>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              {isEdited && (
                <Button
                  circular
                  icon={"close"}
                  className="bottom-10 app-button"
                  onClick={() => setIsEdited(false)}
                  style={{ marginRight: 20 }}
                />
              )}
              <Button
                circular
                icon={isEdited ? "save" : "edit"}
                className="bottom-10 app-button"
                onClick={isEdited ? updateUser : () => setIsEdited(true)}
              />
            </div>
          </Form>
        </Segment>
      </Container>
    </div>
  )
}
export default UserProtocols
