//imports
import React, { useCallback, useState, useEffect } from "react"
import useFetch from "use-http"
import { useTranslation } from "react-i18next"

//UI
import {
  Container,
  Embed,
  Header,
  Table,
  Menu,
  Icon,
  Modal,
  Button,
  Confirm,
  Dropdown,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react"

//App
import AppError from "../shared/AppError"
import Excercise from "./components/Exercise"
import { youTubeGetID } from "../../services/api"

function AdminExercises() {
  //hooks
  const { t } = useTranslation()
  const { get, del, response, loading, error } = useFetch()

  //state
  const [open, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [exercises, setExercises] = useState([])
  const [exercisesCount, setExercisesCount] = useState()
  const [services, setServices] = useState([])
  const [serviceFilter, setServiceFilter] = useState("-")
  const [categories, setCategories] = useState([])
  const [categoryFilter, setCategoryFilter] = useState("-")
  const limit = 30
  const [page, setPage] = useState(1)
  const [toDelete, setToDelete] = useState()
  const [toEdit, setToEdit] = useState()
  const [needle, setNeedle] = useState("")
  const [exercicesLoading, setExercicesLoading] = useState(false)
  const [containsFilter, setContainsFilter] = useState()

  //useEffect functions
  const fetchExercises = useCallback(async () => {
    setExercicesLoading(true)
    const res = await get(
      `/exercises?sort=name&page=${page}&limit=${limit}` +
        (serviceFilter !== "-" ? `&service=${serviceFilter}` : "") +
        (categoryFilter !== "-" ? `&category=${categoryFilter}` : "") +
        (containsFilter ? "&contains=" + containsFilter : "")
    )

    const { count, rows } = res
    if (response.ok) {
      setExercicesLoading(false)
      setExercises(rows)
      setExercisesCount(count)
    }
  }, [get, page, serviceFilter, containsFilter, categoryFilter, response])

  const fetchCategories = useCallback(async () => {
    const categoriesRes = await get(
      `/exercises/categories?` +
        (serviceFilter !== "-" ? `&service=${serviceFilter}` : "")
    )
    if (response.ok) {
      let categoriesOptions = []
      categoriesRes.forEach((element) => {
        if (element) {
          categoriesOptions.push({
            text: element,
            value: element,
          })
        }
      })

      setCategories(categoriesOptions)
    }
  }, [get, serviceFilter, response])

  const fetchServices = useCallback(async () => {
    const res = await get("/services?sort=name")
    const { rows } = res
    if (response.ok) {
      let servicesOptions = []
      rows.forEach((element) => {
        servicesOptions.push({
          text: element.name,
          value: element.id,
        })
      })
      setServices(servicesOptions)
    }
  }, [get, response, setServices])

  useEffect(() => {
    fetchExercises()
  }, [fetchExercises])

  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  //local functions
  const refresh = () => {
    fetchExercises()
    fetchCategories()
    setOpen(false)
  }

  const askDeletion = (toDelete) => {
    setToDelete(toDelete)
    setConfirmOpen(true)
  }
  const confirmDeletion = async () => {
    await del(`/exercises/${toDelete}`)
    if (response.ok) {
      fetchExercises()
    }
    setToDelete(null)
    setConfirmOpen(false)
  }
  const cancelDeletion = () => {
    setToDelete(null)
    setConfirmOpen(false)
  }
  const edit = (toEdit) => {
    setToEdit(toEdit)
    setOpen(true)
  }

  const handleSearchChange = (e, { value }) => {
    if (!value) {
      setNeedle(null)
      setContainsFilter()
    } else {
      setNeedle(value)
      if (value.length >= 3) {
        setContainsFilter(value)
      }
    }
  }

  const resetSearch = () => {
    setNeedle("")
    setContainsFilter()
  }
  return error ? (
    <AppError error={error} />
  ) : (
    <div className=" page admin-exercises">
      <Container>
        <Header as="h2">
          {" "}
          {t("Exercises")}
          <Icon
            name="plus circle"
            style={{ position: "absolute", right: 10, fontSize: "1.2em" }}
            onClick={() => edit({})}
          ></Icon>
        </Header>

        <Form.Input
          fluid
          placeholder="Search"
          icon={
            !needle ? (
              <Icon name="search" />
            ) : (
              <Icon name="close" link onClick={resetSearch} />
            )
          }
          onChange={handleSearchChange}
          value={needle}
        />
        <Dimmer active={exercicesLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  options={[{ text: t("Service"), value: "-" }, ...services]}
                  value={serviceFilter}
                  onChange={(e, { value }) => setServiceFilter(value)}
                  inline
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Dropdown
                  options={[{ text: t("Category"), value: "-" }, ...categories]}
                  value={categoryFilter}
                  onChange={(e, { value }) => setCategoryFilter(value)}
                  inline
                />
              </Table.HeaderCell>
              <Table.HeaderCell>{t("Video")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Prescription")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {exercises.map((x) => (
              <Table.Row key={x.id}>
                <Table.Cell>{x.name}</Table.Cell>
                <Table.Cell>
                  {x.service
                    .map(
                      (service) =>
                        services.find(
                          (s) => s.value.toString() === service.toString()
                        )?.text
                    )
                    .join()}
                </Table.Cell>
                <Table.Cell>{x.category.join(", ")}</Table.Cell>
                <Table.Cell style={{ width: 200 }}>
                  {x.url && (
                    <Embed
                      autoplay={false}
                      brandedUI
                      color="white"
                      hd={false}
                      id={youTubeGetID(x.url)}
                      source="youtube"
                      active={true}
                      iframe={{
                        allowFullScreen: true,
                      }}
                    />
                  )}
                </Table.Cell>
                <Table.Cell>{x.prescription}</Table.Cell>
                <Table.Cell>{x.description}</Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    basic
                    size="small"
                    icon="edit"
                    onClick={() => edit(x)}
                  />
                  <Button
                    basic
                    size="small"
                    icon="trash"
                    onClick={() => askDeletion(x.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}

            {exercisesCount === 0 && !loading && (
              <Table.Row>
                <Table.Cell colspan="10" textAlign="center">
                  {t("No exercise to display ")}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="10">
                {exercisesCount > limit && (
                  <Menu floated="right" pagination>
                    {page - 1 >= 1 && (
                      <Menu.Item as="a" icon onClick={() => setPage(page - 1)}>
                        <Icon name="chevron left" />
                      </Menu.Item>
                    )}
                    {exercisesCount &&
                      Array.from(
                        Array(Math.ceil(exercisesCount / limit)).keys()
                      ).map((x) => (
                        <Menu.Item as="a" onClick={() => setPage(x + 1)}>
                          {x + 1}
                        </Menu.Item>
                      ))}
                    {page + 1 <= Math.ceil(exercisesCount / limit) && (
                      <Menu.Item as="a" icon onClick={() => setPage(page + 1)}>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    )}
                  </Menu>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Modal
          centered={false}
          closeOnDimmerClick={false}
          className="top-50"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Modal.Header>
            {toEdit?.name ? toEdit.name : t("New Exercise")}
            <a
              href="https://studio.youtube.com/"
              target="blank"
              style={{ float: "right" }}
            >
              <Icon name="youtube" className="blue-smith" size="large" />
            </a>
          </Modal.Header>

          <Excercise
            setOpen={setOpen}
            refresh={refresh}
            services={services}
            categories={categories}
            exercise={toEdit}
          />
        </Modal>

        <Confirm
          size="mini"
          open={confirmOpen}
          onCancel={() => cancelDeletion()}
          onConfirm={() => confirmDeletion()}
        />
      </Container>
    </div>
  )
}
export default AdminExercises
