// imports
import React, { useCallback, useEffect, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useFetch from "use-http"

//UI
import {
  Card,
  Container,
  Header,
  Icon,
  Modal,
  Segment,
  Dimmer,
  Loader,
  Button,
  Confirm,
} from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
//App
import { useSPTAUser } from "../../hooks/useSPTAUser"
import VideoCreate from "../shared/VideoCreate"

var localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

function UserVideos() {
  // props

  //hooks
  const { t } = useTranslation()
  const params = useParams()

  const { get, del, response, loading } = useFetch()
  const [SPTAUser] = useSPTAUser()

  //states
  const [open, setOpen] = useState(false)
  const [videos, setVideos] = useState([])
  const [, setVideosCount] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [toDelete, setToDelete] = useState()

  // local variables
  let { service } = params

  //use effect functions
  const fetchVideos = useCallback(async () => {
    const res = await get(
      `/userMedias?patient=${SPTAUser.id}&service=${service}&isMilestone=false&sort=-createdAt`
    )
    const { count, rows } = res

    if (response.ok) {
      setVideos(rows)
      setVideosCount(count)
    }
  }, [service, SPTAUser.id, get, response])

  useEffect(() => {
    fetchVideos()
  }, [fetchVideos])
  //local functions

  const deleteVideo = (videoId) => {
    setToDelete(videoId)
    setConfirmOpen(true)
  }

  const cancelDeletion = () => {
    setToDelete(null)
    setConfirmOpen(false)
  }

  const confirmDeletion = async () => {
    await del(`/userMedias/${toDelete}`)
    if (response.ok) {
      setConfirmOpen(false)
      setVideos((videos) => videos.filter((x) => "" + x.id !== "" + toDelete))
      setToDelete(null)
    }
  }
  return (
    <div className={`page user-videos service-${service}`}>
      <Container>
        <Header as="h2">
          {t("My Videos")}
          <Modal
            centered={false}
            className={`top-50  service-${service}`}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
              <Icon
                name="plus circle"
                style={{ position: "absolute", right: 10, fontSize: "1.2em" }}
              ></Icon>
            }
          >
            <VideoCreate setOpen={setOpen} postUpload={fetchVideos} />
          </Modal>
        </Header>

        <Segment basic>
          <Dimmer active={loading} inverted>
            <Loader inverted />
          </Dimmer>
          {videos?.length === 0 && !loading && (
            <Segment>{t("No video to display yet...")}</Segment>
          )}

          {videos.map((x, i) => (
            <Card fluid className="white-card" key={i}>
              <Card.Content style={{ paddingBottom: 0 }}>
                <Button
                  className="app-button"
                  circular
                  icon="trash"
                  style={{ float: "right" }}
                  onClick={() => deleteVideo(x.id)}
                />
                <Card.Header>{x.title}</Card.Header>
                <Card.Meta>
                  <span className="date">
                    {dayjs(x.createdAt).format("ll")}
                  </span>
                </Card.Meta>
              </Card.Content>

              <Card.Content>
                {x.files?.map((v, i) => (
                  <div className="video" key={i}>
                    <video
                      id="v0"
                      autobuffer="true"
                      preload="true"
                      controls
                      playsInline
                      poster={"/video-poster.png"}
                      muted
                      loop
                      className="video-player"
                    >
                      <source
                        //  type="video/mp4"
                        src={v + "#t=0.1"}
                      />
                    </video>
                  </div>
                ))}
                {/*
              <Button
                circular
                icon={'add'}
                size="tiny"
                className="bottom-10 left-10 app-button add-media"
                onClick={() => {}}
              />
								 */}
              </Card.Content>

              {x.comments && (
                <Card.Content>
                  <h4>
                    {t("{{therapist}} commented :", {
                      therapist: x.therapists[0].firstname,
                    })}
                  </h4>
                  <Card.Description>{x.comments}</Card.Description>
                </Card.Content>
              )}
            </Card>
          ))}
        </Segment>
        <Confirm
          size="mini"
          open={confirmOpen}
          onCancel={() => cancelDeletion()}
          onConfirm={() => confirmDeletion()}
        />
      </Container>
    </div>
  )
}
export default UserVideos
// '//player.vimeo.com/video/464170025?title=0&byline=0&portrait=0&sidedock=0'
