//imports
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
//UI
import {
  Card,
  List,
  Icon,
  Button,
  Dimmer,
  Loader,
  Label,
  Confirm,
  Popup,
} from "semantic-ui-react"
import { toast } from "react-toastify"
//App
import PatientProtocolEdit from "./PatientProtocol"
import UserProtocolDisplay from "../../front/UserProtocolDisplay"
import CopyToClipboard from "react-copy-to-clipboard"

var isBetween = require("dayjs/plugin/isBetween")
dayjs.extend(isBetween)

function PatientProtocols(props) {
  //props
  const {
    expand,
    expandBloc,
    openModal,
    isEditedProtocol: isEdited,
    setIsEditedProtocol: setIsEdited,
    patient,
  } = props

  //hooks
  const { t } = useTranslation()
  const { del, get, response } = useFetch()
  const { id: patientId } = useParams()

  //states
  const [protocols, setProtocols] = useState()
  const [protocolsLoading, setProtocolsLoading] = useState()
  const [toDelete, setToDelete] = useState()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [popupIsOpen, setPopupIsOpen] = useState(false)
  let popupTimeout

  const fetchProtocols = useCallback(async () => {
    setProtocolsLoading(true)
    const res = await get(
      `/protocols?expand=exercises&sort=-startDate&user=` + patientId
    )
    const { rows } = res
    if (response.ok) {
      setProtocols(rows)
      setProtocolsLoading(false)
    }
  }, [patientId, get, response])

  useEffect(() => {
    fetchProtocols()
  }, [fetchProtocols])

  const edit = (x) => {
    setIsEdited(x)
    expandBloc("protocols")
  }
  const askDeletion = (toDelete) => {
    setToDelete(toDelete)
    setConfirmOpen(true)
  }
  const cancelDeletion = () => {
    setToDelete(null)
    setConfirmOpen(false)
  }
  const removeProtocol = async () => {
    setProtocolsLoading(true)

    let id = toDelete

    setToDelete(null)
    setConfirmOpen(false)

    await del(`/protocols/` + id)

    if (response.ok) {
      setProtocols(protocols.filter((x) => "" + x.id !== "" + id))
      setProtocolsLoading(false)
      toast.success(t("Protocol deleted"))
    } else {
      setProtocolsLoading(false)
      toast.error(t("Protocol could not be deleted"))
    }
  }

  const cancel = () => {
    // expandBloc('protocols')
    setIsEdited(false)
  }
  const expandBlocAndResetIsNew = (expand) => {
    setIsEdited(false)
    expandBloc(expand)
  }

  const protocolExercisesList = (protocol) => {
    return protocol.exercises?.map((ex) => ex.name).join(", ")
  }

  const popupOpen = () => {
    setPopupIsOpen(true)

    popupTimeout = setTimeout(() => {
      setPopupIsOpen(false)
    }, 1000)
  }

  const popupClose = () => {
    setPopupIsOpen(false)
    clearTimeout(popupTimeout)
  }
  return (
    <Card fluid>
      <Card.Content className="header-container">
        <Icon
          onClick={() =>
            expand
              ? expandBlocAndResetIsNew(false)
              : expandBlocAndResetIsNew("protocols")
          }
          name={expand ? "compress" : "expand"}
          style={{
            float: "right",
            cursor: "pointer",
          }}
        />
        {!isEdited && (
          <Icon
            onClick={() => edit({})}
            name={"add"}
            style={{
              float: "right",
              cursor: "pointer",
              marginRight: "15px",
            }}
          />
        )}
        {isEdited && (
          <Fragment>
            <Icon
              onClick={() => cancel()}
              name={"close"}
              style={{
                float: "right",
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
          </Fragment>
        )}

        <Card.Header
          onClick={() =>
            expand
              ? expandBlocAndResetIsNew(false)
              : expandBlocAndResetIsNew("protocols")
          }
          style={{ cursor: "pointer" }}
        >
          {t("Protocols")}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Dimmer active={protocolsLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <Card.Description className={!expand ? "card-container" : ""}>
          {isEdited ? (
            <PatientProtocolEdit
              currentProtocol={isEdited}
              removeProtocol={removeProtocol}
              patient={patient}
              expandBlocAndResetIsNew={expandBlocAndResetIsNew}
            />
          ) : (
            <List divided selection>
              {protocols?.map((protocol) => (
                <List.Item key={protocol.id} style={{ position: "relative" }}>
                  <Button.Group
                    basic
                    size="small"
                    style={{
                      float: "right",
                      marginLeft: 5,
                    }}
                  >
                    <Button icon="edit" onClick={() => edit(protocol)} />

                    <Popup
                      content={t(`Copied to clipboard`)}
                      trigger={
                        <CopyToClipboard
                          text={protocolExercisesList(protocol)}
                          onCopy={() => popupOpen()}
                        >
                          <Button
                            title={t("Copy to clipboard")}
                            icon="clipboard"
                          />
                        </CopyToClipboard>
                      }
                      on="click"
                      open={popupIsOpen}
                      onClose={popupClose}
                      onOpen={popupOpen}
                      position="top right"
                    />
                    <Button
                      icon="trash"
                      color="red"
                      basic
                      onClick={() => askDeletion(protocol.id)}
                    />
                  </Button.Group>

                  <div style={{ textAlign: "center" }}>
                    {protocol.startDate && (
                      <Label
                        basic
                        color={
                          dayjs().isBetween(
                            protocol.startDate,
                            dayjs(protocol.endDate),
                            "days",
                            "[["
                          )
                            ? "blue"
                            : null
                        }
                      >
                        {dayjs(protocol.startDate).format("MMM DD")}
                      </Label>
                    )}
                    <Icon
                      name="arrow right"
                      style={{ verticalAlign: "middle", display: "inline" }}
                    />

                    {protocol.endDate && (
                      <Label
                        basic
                        color={
                          dayjs().isBetween(
                            protocol.startDate,
                            dayjs(protocol.endDate),
                            "days",
                            "[["
                          )
                            ? "blue"
                            : null
                        }
                      >
                        {dayjs(protocol.endDate).format("MMM DD")}
                      </Label>
                    )}
                  </div>
                  <List.Description
                    onClick={() =>
                      openModal({
                        content: (
                          <UserProtocolDisplay
                            protocolLoading={false}
                            protocol={protocol}
                          />
                        ),
                      })
                    }
                  >
                    <List.Header>{protocol.name}</List.Header>

                    <List.Content className="content">
                      {protocolExercisesList(protocol)}
                    </List.Content>
                  </List.Description>
                </List.Item>
              ))}
              {!protocols?.length && (
                <List.Item>{t("Nothing to display yet...")}</List.Item>
              )}
            </List>
          )}
        </Card.Description>
      </Card.Content>

      <Confirm
        size="mini"
        open={confirmOpen}
        onCancel={() => cancelDeletion()}
        onConfirm={() => removeProtocol()}
      />
    </Card>
  )
}

export default PatientProtocols
