import { PatientInformations } from "./components/PatientInformations"
//imports
import React, { useState, useEffect, useCallback } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"

//UI
import {
  Container,
  Grid,
  Segment,
  Header,
  Card,
  Modal,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react"
import { Zoom } from "react-awesome-reveal"

//App
import PatientNotes from "./components/PatientNotes"
import PatientProtocols from "./components/PatientProtocols"
import PatientMilestones from "./components/PatientMilestones"
import PatientVideos from "./components/PatientVideos"
import PatientMessages from "./components/PatientMessages"
import PatientComments from "./components/PatientComments"
import UserServicesSelector from "../shared/UserServicesSelector"
import AppError from "../shared/AppError"

function Patient() {
  const { id: patientId, expand: expandParam } = useParams()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [isNewMilestone, setIsNewMilestone] = useState(false)
  const [isNewVideo, setIsNewVideo] = useState(false)
  const [isEditedProtocol, setIsEditedProtocol] = useState(false)
  const [expand, setExpand] = useState(expandParam)
  const [showLeftPane, setShowLeftPane] = useState(true)
  const [showRightPane, setShowRightPane] = useState(true)
  const [modalData, setModalData] = useState({})
  const [patient, setPatient] = useState({})
  const [isServiceEdited, setIsServiceEdited] = useState(false)

  const { get, response, loading, error } = useFetch()
  const [hasError, setHasError] = useState()

  const [services, setServices] = useState([])

  const fetchPatient = useCallback(async () => {
    const patientRes = await get(
      `/users/${patientId}?expand=comments.updatedBy`
    )
    if (response.ok) {
      setPatient(patientRes)
    }
  }, [get, patientId, response])

  useEffect(() => {
    fetchPatient()
  }, [fetchPatient])

  const fetchServices = useCallback(async () => {
    const res = await get("/services?sort=name&expand=true")
    const { rows } = res
    if (response.ok) {
      setServices(rows)
    }
  }, [get, response, setServices])

  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  const openModal = (modalData) => {
    setModalData(modalData)
    setOpen(true)
  }
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      expandBloc()
      setIsEditedProtocol(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  const expandBloc = (bloc) => {
    setExpand(bloc)
    setShowLeftPane(!bloc ? true : bloc === "messages" ? false : true)
    setShowRightPane(!bloc ? true : bloc === "messages" ? true : false)
  }

  return error || hasError ? (
    <AppError error={error} />
  ) : (
    <div className="page admin-patient ">
      <Container fluid style={{ padding: "1em" }}>
        <Header as="h2"> {t("Patient")}</Header>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column width={16}>
              <Dimmer active={loading} inverted>
                <Loader inverted />
              </Dimmer>
              <Segment>
                {!expand && (
                  <Grid columns={3}>
                    <Grid.Row stretched>
                      <Grid.Column>
                        <PatientInformations
                          t={t}
                          patient={patient}
                          setPatient={setPatient}
                        ></PatientInformations>
                      </Grid.Column>
                      <Grid.Column>
                        <Card fluid>
                          <Card.Content>
                            <Card.Header>
                              {t("Therapists & Services")}
                              <Button
                                basic
                                icon={isServiceEdited ? "close" : "edit"}
                                onClick={() =>
                                  setIsServiceEdited(!isServiceEdited)
                                }
                                floated="right"
                              />
                            </Card.Header>

                            <UserServicesSelector
                              user={patient}
                              setHasError={setHasError}
                              isEdited={isServiceEdited}
                              hidePictures={false}
                              services={services}
                              mustUpdateSPTAUser={false}
                            />
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                      <Grid.Column>
                        <PatientComments patient={patient} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
                <Grid stackable columns={2} className="content-cards top-10">
                  <Grid.Row stretched>
                    {showLeftPane && (
                      <Grid.Column width={expand ? 16 : 12}>
                        <Grid stackable columns={expand ? 1 : 2}>
                          <Grid.Row stretched className="main-content-row">
                            {!expand && (
                              <Grid.Column>
                                <PatientNotes
                                  patientId={patientId}
                                  expand={expand}
                                  expandBloc={expandBloc}
                                />
                              </Grid.Column>
                            )}
                            {expand === "notes" && (
                              <Grid.Column>
                                <Zoom duration={200}>
                                  <PatientNotes
                                    patientId={patientId}
                                    expand={expand}
                                    expandBloc={expandBloc}
                                  />
                                </Zoom>
                              </Grid.Column>
                            )}
                            {!expand && (
                              <Grid.Column>
                                <PatientProtocols
                                  expand={expand}
                                  expandBloc={expandBloc}
                                  openModal={openModal}
                                  isEditedProtocol={isEditedProtocol}
                                  setIsEditedProtocol={setIsEditedProtocol}
                                />
                              </Grid.Column>
                            )}
                            {expand === "protocols" && (
                              <Grid.Column>
                                <Zoom duration={200}>
                                  <PatientProtocols
                                    expand={expand}
                                    expandBloc={expandBloc}
                                    openModal={openModal}
                                    isEditedProtocol={isEditedProtocol}
                                    setIsEditedProtocol={setIsEditedProtocol}
                                    patient={patient}
                                  />
                                </Zoom>
                              </Grid.Column>
                            )}
                            {!expand && (
                              <Grid.Column>
                                <PatientMilestones
                                  expand={expand}
                                  expandBloc={expandBloc}
                                  openModal={openModal}
                                  isNewMilestone={isNewMilestone}
                                  setIsNewMilestone={setIsNewMilestone}
                                  patientId={patientId}
                                  patientServices={services.filter((x) =>
                                    patient.services.includes(x.id)
                                  )}
                                />
                              </Grid.Column>
                            )}
                            {expand === "milestones" && (
                              <Grid.Column>
                                <Zoom duration={200}>
                                  <PatientMilestones
                                    expand={expand}
                                    expandBloc={expandBloc}
                                    openModal={openModal}
                                    isNewMilestone={isNewMilestone}
                                    setIsNewMilestone={setIsNewMilestone}
                                    patientId={patientId}
                                    patientServices={services.filter((x) =>
                                      patient.services.includes(x.id)
                                    )}
                                  />
                                </Zoom>
                              </Grid.Column>
                            )}
                            {!expand && (
                              <Grid.Column>
                                <PatientVideos
                                  expand={expand}
                                  expandBloc={expandBloc}
                                  openModal={openModal}
                                  isNewVideo={isNewVideo}
                                  setIsNewVideo={setIsNewVideo}
                                  patientId={patientId}
                                />
                              </Grid.Column>
                            )}
                            {expand === "videos" && (
                              <Grid.Column>
                                <Zoom duration={200}>
                                  <PatientVideos
                                    expand={expand}
                                    expandBloc={expandBloc}
                                    openModal={openModal}
                                    isNewVideo={isNewVideo}
                                    setIsNewVideo={setIsNewVideo}
                                    patientId={patientId}
                                  />
                                </Zoom>
                              </Grid.Column>
                            )}
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    )}
                    {showRightPane &&
                      (expand !== "messages" ? (
                        <Grid.Column width={expand ? 16 : 4}>
                          <PatientMessages
                            expand={expand}
                            expandBloc={expandBloc}
                            patientId={patientId}
                          />
                        </Grid.Column>
                      ) : (
                        <Grid.Column width={expand ? 16 : 4}>
                          <Zoom duration={200}>
                            <PatientMessages
                              expand={expand}
                              expandBloc={expandBloc}
                              patientId={patientId}
                            />
                          </Zoom>
                        </Grid.Column>
                      ))}
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Modal
        closeIcon
        className="admin admin-patient"
        style={{ maxWidth: "550px" }}
        size="small"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>{modalData.header}</Modal.Header>
        <Modal.Content>
          <Modal.Description>{modalData.content}</Modal.Description>
        </Modal.Content>
        {modalData.actions && (
          <Modal.Actions>{modalData.actions}</Modal.Actions>
        )}
      </Modal>
    </div>
  )
}

//https://loremflickr.com/320/240/face
export default Patient
