//imports
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useFormState } from "react-use-form-state"
import { Redirect, useHistory, Link } from "react-router-dom"
import useLocalStorage from "react-use-localstorage"
import useFetch from "use-http"
//UI
import {
  Button,
  Form,
  Grid,
  Segment,
  Container,
  Message,
} from "semantic-ui-react"

//App
import Loading from "../shared/Loading"
import { useSPTAUser } from "../../hooks/useSPTAUser"
import { toast } from "react-toastify"
import { Config } from "../../Config"

const Signup = (props) => {
  // local variables
  const from = "/"
  const defaultPassword = Config.defaultPatientPassword

  // props
  const { isEmbedded, postRegister } = props

  //hooks
  const { t } = useTranslation()
  const { get, post, response, loading, error } = useFetch()

  //states
  const [services, setServices] = useState([])
  const [formState, { text, email, password, raw }] = useFormState({
    services: "",
  })
  const [SPTAToken, setSPTAToken] = useLocalStorage("SPTAToken")
  const [SPTAUser, updateSPTAUser] = useSPTAUser()
  //use effect functions

  //local functions

  let history = useHistory()

  useEffect(() => {
    const fetchServices = async () => {
      const services = await get("/services?sort=name")

      if (response.ok) {
        let servicesOptions = []
        services.rows.forEach((element) => {
          servicesOptions.push({
            text: element.name,
            value: element.id,
          })
        })
        setServices(servicesOptions)
      }
    }
    fetchServices()
  }, [get, response])

  const signup = async () => {
    var payload = { ...formState.values, services: [formState.values.services] }
    const { token, user } = await post("/users", payload)
    if (response.ok) {
      setSPTAToken(token)
      updateSPTAUser(user)
      history.push("/")
    }
  }

  const register = async () => {
    formState.setField("password", defaultPassword)

    var payload = {
      ...formState.values,
      services: [formState.values.services],
      password: defaultPassword,
      therapists: [
        { service: formState.values.services, therapist: SPTAUser.id },
      ],
    }
    await post("/users/register", payload)
    if (response.ok) {
      postRegister()
    } else {
      if (isEmbedded) {
        toast.error(t("Could not register patient"))
      }
    }
  }

  useEffect(() => {
    const { password, password_confirm } = formState.values
    if (password !== password_confirm) {
      formState.setFieldError("password_confirm", t("passwords must match"))
    }
  }, [
    formState,
    formState.values.password,
    formState.values.password_confirm,
    t,
  ])

  return (
    <div className={"login " + (isEmbedded && "page")}>
      {SPTAToken && !isEmbedded && <Redirect to={from} />}
      <Container fluid style={{ padding: "1em" }}>
        <Grid textAlign="center" style={{ height: "80vh" }}>
          <Grid.Column style={{ maxWidth: 350 }}>
            <Form size="large" onSubmit={isEmbedded ? register : signup}>
              <Segment stacked>
                <Form.Input
                  fluid
                  label="Firstname"
                  placeholder="Firstname"
                  {...text("firstname")}
                  required
                />{" "}
                <Form.Input
                  fluid
                  label="Lastname"
                  placeholder="Lastname"
                  {...text("lastname")}
                  required
                />
                <Form.Input
                  fluid
                  label="E-mail address"
                  placeholder="E-mail"
                  {...email("email")}
                  required
                />
                <Form.Select
                  fluid
                  label="Service"
                  placeholder="Service"
                  {...raw({
                    name: "services",
                    //onChange: (service, t1) => console.log(service, t1),
                  })}
                  onChange={(e, { value }) =>
                    formState.setField("services", value)
                  }
                  options={services}
                  value={formState.values.services}
                  required
                />
                {!isEmbedded && (
                  <>
                    <Form.Input
                      fluid
                      label="Password"
                      placeholder="Password"
                      type="password"
                      minLength="8"
                      {...password("password")}
                      required
                    />
                    <Form.Input
                      fluid
                      placeholder="Password confirm"
                      type="password"
                      minLength="8"
                      {...password("password_confirm")}
                      required
                      error={
                        formState.values.password !==
                        formState.values.password_confirm
                          ? true
                          : false
                      }
                    />
                  </>
                )}
                {error && (
                  <Message negative>
                    <p>{response.data?.message || response.data}</p>
                  </Message>
                )}
                <Button type="submit" fluid size="large" className="app-button">
                  {loading ? (
                    <Loading />
                  ) : isEmbedded ? (
                    t("Register patient")
                  ) : (
                    t("Sign up")
                  )}
                </Button>
              </Segment>
            </Form>
            {!isEmbedded && (
              <Message>
                {t("Already registered?")}{" "}
                <Link to="/login">{t("Sign in")}</Link>
              </Message>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  )
}

export default Signup
