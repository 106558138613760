//imports
import React, { useState, useEffect, useCallback, Fragment } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"

//UI
import { Icon, Card, List, Button, Confirm } from "semantic-ui-react"
import ReactCardFlip from "react-card-flip"

//App
import PatientNoteEdit from "./PatientNoteEdit"
var localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

function PatientNotes(props) {
  // props
  const { expand, expandBloc, patientId } = props

  //hooks
  const { t } = useTranslation()
  const { get, del, response } = useFetch()

  //states
  const [isNew, setIsNew] = useState(false)
  const [isEdited, setIsEdited] = useState()
  const [notes, setNotes] = useState()
  const [notesCount, setNotesCount] = useState()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [toDelete, setToDelete] = useState()

  //use effect functions
  const fetchNotes = useCallback(async () => {
    const res = await get(`/patientNotes?patient=${patientId}&sort=-createdAt`)
    const { count, rows } = res
    if (response.ok) {
      setNotes(rows)
      setNotesCount(count)
    }
  }, [patientId, get, response])

  useEffect(() => {
    fetchNotes()
  }, [fetchNotes])
  //local functions

  const createNew = () => {
    // expandBloc('protocols')
    setIsNew(true)
  }

  const postSave = () => {
    // expandBloc('protocols')
    setIsNew(false)
    setIsEdited(false)
    fetchNotes()
  }

  const cancel = () => {
    // expandBloc('protocols')
    setIsNew(false)
    setIsEdited(false)
  }
  const deleteNote = (noteId) => {
    setToDelete(noteId)
    setConfirmOpen(true)
  }

  const cancelDeletion = () => {
    setToDelete(null)
    setConfirmOpen(false)
  }

  const confirmDeletion = async () => {
    await del(`/patientNotes/${toDelete}`)
    if (response.ok) {
      postSave()
    }
    setToDelete(null)
    setConfirmOpen(false)
  }
  return (
    <Card fluid>
      <Card.Content className="header-container">
        <Icon
          onClick={() => (expand ? expandBloc() : expandBloc("notes"))}
          name={expand ? "compress" : "expand"}
          style={{
            float: "right",
            cursor: "pointer",
          }}
        />
        {!isNew && (
          <Icon
            onClick={() => createNew()}
            name={"add"}
            style={{
              float: "right",
              cursor: "pointer",
              marginRight: "15px",
            }}
          />
        )}

        <Card.Header
          onClick={() => (expand ? expandBloc() : expandBloc("notes"))}
          style={{ cursor: "pointer" }}
        >
          {t("Notes")}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <ReactCardFlip isFlipped={isNew || isEdited} flipDirection="vertical">
          <Fragment>
            <Card.Meta textAlign="right">
              {notesCount > 0 && (
                <span className="date">
                  Updated : {dayjs(notes[0].createdAt).format("ll")}
                </span>
              )}
            </Card.Meta>
            <Card.Description className={!expand ? "card-container" : ""}>
              <List divided selection>
                {notes?.map((x) => (
                  <List.Item key={x.id}>
                    <Button.Group
                      basic
                      size="small"
                      style={{
                        float: "right",
                        marginLeft: 5,
                      }}
                    >
                      <Button icon="edit" onClick={() => setIsEdited(x)} />
                      <Button icon="trash" onClick={() => deleteNote(x.id)} />
                    </Button.Group>
                    <List.Header className="bottom-10">
                      {dayjs(x.createdAt).format("ll")}
                    </List.Header>

                    <List.Content className="content">
                      {x.comments}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Card.Description>
          </Fragment>
          <PatientNoteEdit
            patientId={patientId}
            note={isEdited}
            postSave={postSave}
            cancel={cancel}
          />
        </ReactCardFlip>
      </Card.Content>
      <Confirm
        size="mini"
        open={confirmOpen}
        onCancel={() => cancelDeletion()}
        onConfirm={() => confirmDeletion()}
      />
    </Card>
  )
}

export default PatientNotes
