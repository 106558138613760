// imports
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"
import { toast } from "react-toastify"
import { useFormState } from "react-use-form-state"
import dayjs from "dayjs"

//UI
import { Form, Button, Card, Message, Image, Confirm } from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
//App
import { isImage, isVideo } from "../../../services/api"

function PatientMilestone(props) {
  // props
  const {
    postUpdate,
    setOpen,
    milestone,
    isEdited,
    setIsEdited,
    feedCarousel,
  } = props
  //hooks
  const { t } = useTranslation()

  const { put, del, response } = useFetch()
  const [formState, { text, textarea }] = useFormState(milestone)

  //states
  const [hasError, setHasError] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [toDelete, setToDelete] = useState()

  // local variables

  const saveMilestone = async () => {
    if (!formState.values.title) {
      setHasError(t("Please enter a title"))
      return
    }
    if (!formState.values.comments) {
      setHasError(t("Please enter a message "))
      return
    }
    var payload = { ...formState.values }
    await put(`/userMedias/update/${milestone.id}`, payload)
    if (response.ok) {
      toast.success(t("Milestone sucessfully updated"))
      setIsEdited(false)
      postUpdate()
    } else {
      toast.error(t("Could not update milestone"))
      return false
    }
  }
  const deleteMilestone = (milestoneId) => {
    setToDelete(milestoneId)
    setConfirmOpen(true)
  }

  const cancelDeletion = () => {
    setToDelete(null)
    setConfirmOpen(false)
  }

  const confirmDeletion = async () => {
    await del(`/userMedias/${toDelete}`)
    if (response.ok) {
      postUpdate()
    }
    setToDelete(null)
    setConfirmOpen(false)
  }
  return (
    <Fragment>
      <Card className="white-card  progress-photos new-form-card">
        <Card.Content>
          <Card.Header>{formState.values.title || milestone.title}</Card.Header>
          <Form className="upload-form">
            <Button.Group
              basic
              size="small"
              style={{
                float: "right",
                marginLeft: 5,
              }}
            >
              {isEdited ? (
                <Fragment>
                  <Button icon="save" onClick={() => saveMilestone()} />
                  <Button icon="close" onClick={() => setIsEdited(false)} />
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    icon="edit"
                    onClick={() => setIsEdited(milestone.id)}
                  />
                  <Button
                    icon="trash"
                    onClick={() => deleteMilestone(milestone.id)}
                  />
                </Fragment>
              )}
            </Button.Group>
            {hasError && (
              <Message negative>
                <p>
                  {response.data?.message}
                  {hasError}
                </p>
              </Message>
            )}
            {isEdited ? (
              <Fragment>
                <Form.Input
                  fluid
                  label="Title"
                  placeholder="Title"
                  {...text("title")}
                  required
                />
                <Form.TextArea
                  label="Comments"
                  placeholder="Comments"
                  {...textarea("comments")}
                  required
                  rows={5}
                />
              </Fragment>
            ) : (
              <Fragment>
                <Card.Header>
                  {milestone.title} - {dayjs(milestone.createdAt).format("ll")}
                </Card.Header>
                <Card.Description>{milestone.comments}</Card.Description>
              </Fragment>
            )}
            <Card.Content>
              <Image.Group className="top-20">
                {milestone.files
                  .filter((x) => isImage(x))
                  .map((img, i) => (
                    <Image
                      key={i}
                      src={img}
                      onClick={() => {
                        feedCarousel({
                          images: milestone.files.filter((x) => isImage(x)),
                          selectedItem: i,
                        })
                        setOpen(true)
                      }}
                    />
                  ))}
              </Image.Group>
            </Card.Content>

            <Card.Content>
              {milestone.files
                .filter((x) => isVideo(x))
                .map((v, i) => (
                  <div className="video" key={i}>
                    <video
                      id="v0"
                      autobuffer="true"
                      preload="true"
                      controls
                      playsInline
                      poster={"/video-poster.png"}
                      muted
                      loop
                      className="video-player"
                    >
                      <source
                        //  type="video/mp4"
                        src={v + "#t=0.1"}
                      />
                    </video>
                  </div>
                ))}
            </Card.Content>
          </Form>
        </Card.Content>
      </Card>
      <Confirm
        size="mini"
        open={confirmOpen}
        onCancel={() => cancelDeletion()}
        onConfirm={() => confirmDeletion()}
      />
    </Fragment>
  )
}
export default PatientMilestone
