// imports
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"

//UI
import { Grid, Segment, Loader } from "semantic-ui-react"

//App
import { useSPTAUser } from "../../hooks/useSPTAUser"

function Home() {
  // local variables
  const { innerHeight: height } = window
  const containerHeight = height - 70 - 40 //30: marge de bas de page
  const blocsHeight = containerHeight / 4
  let paddingTop = blocsHeight / 3
  paddingTop = 20

  // props

  //hooks
  const { t } = useTranslation()

  const [SPTAUser, updateSPTAUser] = useSPTAUser()
  const { current: me } = useRef(SPTAUser)
  const { get, response, loading } = useFetch()

  //states

  const [services, setServices] = useState([])

  //use effect functions
  const fetchServices = useCallback(async () => {
    const allServices = await get("/services")
    if (response.ok) {
      setServices(allServices?.rows)
    }
  }, [get, response])

  const servicesIds = useMemo(() => {
    const ids = {}
    services.forEach((service) => {
      ids[service.code] = service.id
    })
    return ids
  }, [services]) // Dependency array
  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  const hasService = (serviceCode) => {
    return me?.services?.includes(serviceCode)
  }

  const getCurrentUser = useCallback(async () => {
    const me = await get("/users/me")
    if (response.ok) {
      updateSPTAUser(me)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // componentDidMount

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser]) // componentDidMount

  //local function

  const servicesToDisplay = [
    { code: "running", label: t("Running") },
    { code: "pt", label: t("Physical Therapy") },
    { code: "women", label: t(`Pelvic Health`) },
    { code: "head", label: t(`Head & Balance`) },
    { code: "arts", label: t(`Dance medicine`) },
    { code: "golf", label: t(`Golf medicine`) },
    { code: "performance", label: t(`Performance & Recovery`) },
    { code: "aquatic", label: t(`Aquatic Rehab`) },
  ]
  return (
    <div className="page home">
      <Grid container columns={2} stackable>
        {servicesToDisplay.map(({ code, label }) => (
          <Grid.Column>
            <Link
              to={
                hasService(servicesIds[code])
                  ? "/service/" + servicesIds[code]
                  : ""
              }
            >
              <Segment
                className={
                  `bloc ${code} ` +
                  (!hasService(servicesIds[code]) ? "inactive" : "")
                }
                style={{ height: blocsHeight, paddingTop: paddingTop }}
              >
                <div>
                  {" "}
                  {loading ? (
                    <Loader
                      active={loading}
                      inverted
                      style={{ marginLeft: -50 }}
                    />
                  ) : (
                    label
                  )}
                </div>
              </Segment>
            </Link>
          </Grid.Column>
        ))}
      </Grid>
    </div>
  )
}
export default Home
