//imports
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { useReactToPrint } from "react-to-print"

//UI
import {
  Card,
  Icon,
  Embed,
  Button,
  Dimmer,
  Loader,
  Image,
  Divider,
} from "semantic-ui-react"
import { youTubeGetID } from "../../services/api"

function UserProtocolsDisplay(props) {
  //props
  const { protocolLoading, protocol } = props

  //hooks
  const { t } = useTranslation()

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <Card fluid className="white-card" ref={componentRef}>
      <style>
        {`
			.print-only{
				display:none !important;
			}
			@media print {
				.no-print, .no-print *
				{
						display: none !important;
				}

				.print-only{
					display:block!important;
				}
				.card.fluid.exercise
				{
				width:300px!important;
				float:left;
				margin:15px;
				}
				@page { size: landscape; }
			}
		`}
      </style>
      <Dimmer active={protocolLoading} inverted>
        <Loader inverted />
      </Dimmer>
      <Card.Content style={{ paddingBottom: 0 }}>
        <Button
          icon
          basic
          style={{ float: "right" }}
          onClick={handlePrint}
          className="no-print"
        >
          <Icon name="print" />
        </Button>
        <Card.Header>{protocol?.name}</Card.Header>
        <Card.Meta>
          {protocol?.startDate && (
            <span className="date" style={{ fontStyle: "italic" }}>
              <span style={{ fontSize: "0.9em" }}> {t("From")}</span>{" "}
              <span style={{ fontSize: "1.1em" }}>
                {dayjs(protocol.startDate).format("MMM DD")}
              </span>
            </span>
          )}
          {protocol?.endDate && (
            <span className="date" style={{ fontStyle: "italic" }}>
              <span style={{ fontSize: "0.9em" }}>{t("until")}</span>
              <span style={{ fontSize: "1.1em" }}>
                {dayjs(protocol.endDate).format("MMM DD")}
              </span>
            </span>
          )}
        </Card.Meta>
        <Card.Description>{protocol?.comments}</Card.Description>
        <div
          style={{ textAlign: "center", paddingTop: 10 }}
          className="no-print"
        >
          <Link
            to={`/messages/${encodeURI(
              t("About Protocol : ") + protocol?.name
            )}`}
          >
            <Icon name="mail" size="large" />
            {t("A question ? Ask {{therapist}}", {
              therapist: protocol?.creator?.firstname,
            })}
          </Link>
        </div>
      </Card.Content>
      <Card.Content>
        {protocol?.exercises?.map((exercise) => (
          <Card
            fluid
            className="exercise"
            style={{ marginTop: 15 }}
            key={exercise.id}
          >
            {exercise.url && (
              <Embed
                autoplay={false}
                brandedUI
                color="white"
                hd={false}
                id={youTubeGetID(exercise.url)}
                source="youtube"
                active={true}
                iframe={{
                  allowFullScreen: true,
                }}
                className="no-print"
              />
            )}

            <Image
              src={`https://img.youtube.com/vi/${youTubeGetID(
                exercise.url
              )}/sddefault.jpg`}
              className="print-only"
            />
            <Card.Content>
              <Card.Header>{exercise.name}</Card.Header>
              <Card.Description style={{ whiteSpace: "pre-line" }}>
                {exercise.description}
              </Card.Description>
              <Divider />
              <Card.Content extra>
                {protocol.prescriptionOverrides.find(
                  (el) => el.exerciseId.toString() === exercise.id.toString()
                )?.prescription || exercise.prescription}
              </Card.Content>
            </Card.Content>
          </Card>
        ))}
      </Card.Content>
    </Card>
  )
}
export default UserProtocolsDisplay
// '//player.vimeo.com/video/464170025?title=0&byline=0&portrait=0&sidedock=0'
