//imports
import React, { Fragment, useState } from "react"

//UI
import { Image, List, Button, Form, Icon, Ref, Popup } from "semantic-ui-react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import logo192 from "../../../images/logo192.png"
import { youTubeGetID } from "../../../services/api"

function PatientProtocolChosenExercises(props) {
  //props
  const {
    removeExercise,
    chosenExercises,
    setChosenExercises,
    prescriptionOverrides,
    editItem,
    isEdited,
  } = props

  //hooks

  //state
  const [prescription, setPrescription] = useState(
    prescriptionOverrides.find(
      (el) => el.exerciseId?.toString() === isEdited?.toString()
    )?.prescription
  )

  const onPrescriptionChange = (e, data) => {
    let { id, value } = data
    let overrides = prescriptionOverrides

    overrides.find(
      (el) => el.exerciseId.toString() === id.toString()
    ).prescription = value
    setPrescription(value)
  }

  const save = () => {
    let overrides = prescriptionOverrides

    overrides.find(
      (el) => el.exerciseId.toString() === isEdited.toString()
    ).prescription = prescription
    editItem(null, false)
    //  savePrescription(overrides)
  }

  const handleOnDragEnd = (result) => {
    const items = Array.from(chosenExercises)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setChosenExercises(items)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="chosenExercices">
        {(provided) => (
          <Ref innerRef={provided.innerRef}>
            <List
              divided
              verticalAlign="middle"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {chosenExercises.map((exercise, index) => (
                <Draggable
                  key={exercise.id}
                  draggableId={"" + exercise.id}
                  index={index}
                >
                  {(provided) => (
                    <Ref innerRef={provided.innerRef}>
                      <List.Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <List.Content floated="right">
                          <Button.Group
                            basic
                            size="small"
                            style={{ marginRight: 10 }}
                          >
                            {isEdited !== exercise.id && (
                              <Button basic icon>
                                <Icon
                                  name="edit"
                                  value={exercise.id}
                                  onClick={editItem}
                                />
                              </Button>
                            )}

                            {isEdited === exercise.id && (
                              <Fragment>
                                <Button basic icon>
                                  <Icon
                                    name="save"
                                    size="small"
                                    value={false}
                                    onClick={save}
                                  />
                                </Button>
                                <Button basic icon>
                                  <Icon
                                    name="close"
                                    size="small"
                                    value={false}
                                    onClick={editItem}
                                  />
                                </Button>
                              </Fragment>
                            )}
                          </Button.Group>
                          <Button
                            className="app-button circular"
                            icon="chevron right"
                            onClick={() => removeExercise(exercise)}
                          />
                        </List.Content>
                        <Image avatar src={logo192} />
                        <List.Content>
                          <Popup
                            trigger={
                              <List.Header>
                                {exercise.name}
                                <span style={{ fontWeight: "normal" }}>
                                  ( {exercise.category?.join(", ")})
                                </span>
                              </List.Header>
                            }
                            hoverable
                          >
                            <Image
                              src={`//img.youtube.com/vi/${youTubeGetID(
                                exercise.url
                              )}/0.jpg `}
                            />
                          </Popup>

                          <List.Description className="rep-scheme">
                            {isEdited !== exercise.id ? (
                              prescriptionOverrides.find(
                                (el) =>
                                  el.exerciseId.toString() ===
                                  exercise.id.toString()
                              )?.prescription ||
                              chosenExercises.find(
                                (el) =>
                                  el.id.toString() === exercise.id.toString()
                              ).prescription
                            ) : (
                              <Form
                                style={{
                                  minWidth: "250px",
                                  display: "inline-block",
                                }}
                              >
                                <Form.TextArea
                                  label="Notes"
                                  rows={3}
                                  id={"" + exercise.id}
                                  value={
                                    prescriptionOverrides.find(
                                      (el) =>
                                        el.exerciseId.toString() ===
                                        exercise.id.toString()
                                    ).prescription ||
                                    chosenExercises.find(
                                      (el) =>
                                        el.id.toString() ===
                                        exercise.id.toString()
                                    ).prescription
                                  }
                                  onChange={onPrescriptionChange}
                                />
                              </Form>
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </Ref>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </Ref>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default PatientProtocolChosenExercises
