import { create } from "apisauce"
export function updateSPTAUser(user) {
  let {
    firstname,
    lastname,
    id,
    picture,
    role,
    therapists,
    services,
    updatedAt,
    email,
  } = user

  window.localStorage.setItem(
    "SPTAUser",
    // setSPTAUser(
    JSON.stringify({
      firstname,
      lastname,
      id,
      picture,
      role,
      updatedAt,
      therapists,
      email,
      services,
    })
  )
}

export const getSPTAUser = () => window.localStorage.getItem("SPTAUser")

export function youTubeGetID(url) {
  url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

export const handleUpload = async (
  filesToUpload,
  setProgressFn,
  post,
  response
) => {
  const progressTracker = (filekey, progress, setProgressFn) => {
    const { loaded, total } = progress
    let uploadPct = Math.round((loaded / total) * 100)
    uploadPct = uploadPct < 0 ? 0 : uploadPct
    setProgressFn((progressPct) => ({
      ...progressPct,
      [filekey]: uploadPct,
    }))
  }
  let files = []
  let uploadError = false

  await Promise.all(
    filesToUpload.map(async (file) => {
      let fileName = file.name.toLowerCase()
      let fileType = fileName.split(".").pop()
      let fileKey = fileName.split(".").join("")
      if (!fileName) return

      let res = await post("/upload/s3credentials/", {
        fileName,
        fileType,
      })

      if (!response.ok) {
        return false
      }

      let { uploadUrl, finalUrl } = res

      let s3Api = create({
        headers: {
          "Content-Type": fileType,
          "Content-Disposition": "attachment",
        },
      })
      let upload = await s3Api.put(uploadUrl, file, {
        onUploadProgress: (value) =>
          progressTracker(fileKey, value, setProgressFn),
      })

      if (!upload.ok) {
        uploadError = fileName

        return false
      }
      files.push(finalUrl)
    })
  )

  if (uploadError) return { ok: false, files: [], error: uploadError }

  return { ok: true, files }
}

export const isVideo = (filename) => {
  let fileType = filename.split(".").pop()
  return ["mp4", "mov"].includes(fileType)
}
export const isImage = (filename) => {
  let fileType = filename.split(".").pop()
  return ["jpg", "jpeg", "png"].includes(fileType)
}
