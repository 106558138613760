//imports
import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

//UI
import {
  Menu,
  Image,
  Icon,
  Sidebar,
  Segment,
  Button,
  Modal,
  Dropdown,
} from "semantic-ui-react"

//App
import useLogout from "../../../hooks/useLogout"
import AvatarEditor from "../../shared/AvatarEditor"
import { useSPTAUser } from "../../../hooks/useSPTAUser"

function AdminSidebar(props) {
  //props

  const { isTabletOrMobile, visible, setVisible } = props

  //hooks
  const { t } = useTranslation()
  const logout = useLogout()

  //states
  const [SPTAUser] = useSPTAUser()
  const [open, setOpen] = useState(false)

  const doLogout = (_) => {
    //props.setVisible(false)
    closeSideBar(false)
    logout()
  }
  const toggleOpenAvatarEditor = () => {
    setOpen(!open)
  }

  const closeSideBar = (_) => {
    if (isTabletOrMobile) {
      setVisible(!visible)
    }
  }

  return (
    <Sidebar
      as={Menu}
      inverted
      vertical
      visible={props.visible}
      className={"admin-menu"}
      onHide={() => setVisible(false)}
    >
      {isTabletOrMobile && (
        <div className="close">
          <Icon name="close" onClick={() => setVisible(false)} />
        </div>
      )}
      <div style={{ position: "relative" }}>
        <Image
          src={
            SPTAUser?.picture +
            (SPTAUser?.picture?.indexOf("gravatar") === -1
              ? "?cache=" + SPTAUser.updatedAt
              : "")
          }
          centered
          size="medium"
        />
        <span onClick={toggleOpenAvatarEditor}>
          <Button
            circular
            icon="pencil"
            className={" app-button "}
            style={{
              position: "absolute",
              right: 10,
              bottom: -10,
              zIndex: 10,
            }}
          />
        </span>
      </div>
      <Segment basic textAlign="center" className="username">
        {`${SPTAUser.firstname} ${SPTAUser.lastname}`}
      </Segment>
      <Menu.Item
        as={NavLink}
        exact
        to="/pt/"
        onClick={() => closeSideBar(false)}
      >
        <Icon name="home" />
        {t("Home")}
      </Menu.Item>
      <Menu.Item
        as={NavLink}
        to="/pt/patients"
        onClick={() => closeSideBar(false)}
      >
        <Icon name="address card" />
        {t("Patients")}
      </Menu.Item>
      <Dropdown
        item
        icon="cogs"
        text={t("Settings")}
        style={{ paddingLeft: 0 }}
      >
        <Dropdown.Menu style={{ left: 0, width: "100%" }}>
          <Dropdown.Item icon="cogs" text={t("Settings")} />
          <Dropdown.Item
            as={NavLink}
            text={t("Exercises")}
            icon="cog"
            to="/pt/exercises"
            onClick={() => closeSideBar(false)}
          />
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item as={NavLink} to="/profile" onClick={() => closeSideBar(false)}>
        <Icon name="user" />
        {t("Profile")}
      </Menu.Item>
      <Menu.Item
        as={NavLink}
        to="/password"
        onClick={() => closeSideBar(false)}
      >
        <Icon name="lock" />
        {t("Change Password")}
      </Menu.Item>
      <Menu.Item as={NavLink} to="/pt/login" onClick={() => doLogout()}>
        <Icon name="sign-out" flipped="horizontally" />
        {t("Log out")}
      </Menu.Item>{" "}
      <Modal
        style={{ textAlign: "center" }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        basic
      >
        <Modal.Content>
          <Modal.Description>
            <AvatarEditor
              image={
                SPTAUser.picture +
                (SPTAUser.picture?.indexOf("gravatar") === -1
                  ? "?cache=" + SPTAUser.updatedAt
                  : "")
              }
              setOpen={setOpen}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Sidebar>
  )
}

export default AdminSidebar
