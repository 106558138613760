import React, { useState, createRef, Fragment } from 'react'
import ReactAvatarEditor from 'react-avatar-editor'
import { Button } from 'semantic-ui-react'
import ButtonLoading from '../shared/ButtonLoading'
import { useSPTAUser } from '../../hooks/useSPTAUser'
import Dropzone from 'react-dropzone'
import useFetch from 'use-http'
import AppError from '../shared/AppError'
function AvatarEditor(props) {
  const [image, setImage] = useState(props.image)
  const [rotation, setRotation] = useState(0)
  const [SPTAUser, updateSPTAUser] = useSPTAUser()

  const { post, response, loading, error } = useFetch()
  const handleDrop = (dropped) => {
    setImage(dropped[0])
  }
  const dropzoneRef = createRef()

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }
  const rotate = (angle) => {
    setRotation(rotation + angle)
  }

  let editor
  const setEditorRef = (editorparam) => {
    if (editorparam) editor = editorparam
  }
  const handleFileUpload = async () => {
    const formData = new FormData()
    formData.append(
      'file',
      dataURLToBlob(editor.getImageScaledToCanvas().toDataURL())
    )
    formData.append('userId', SPTAUser.id)
    const user = await post('/users/upload', formData)
    if (response.ok) {
      updateSPTAUser(user)
      props.setOpen(false)
    }
  }

  return error ? (
    <AppError error={error} />
  ) : (
    <div className=" avatar-editor">
      <Dropzone
        ref={dropzoneRef}
        onDrop={handleDrop}
        noKeyboard
        noClick
        style={{ width: '250px', height: '250px' }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <ReactAvatarEditor
              ref={setEditorRef}
              image={image}
              width={250}
              height={250}
              border={10}
              crossOrigin="anonymous"
              scale={1.2}
              rotate={parseFloat(rotation)}
            />

            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <div style={{ textAlign: 'center', marginTop: '0px' }}>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Fragment>
            <Button
              className="app-button"
              onClick={openDialog}
              icon="images"
              circular
            />

            <Button
              className="app-button"
              onClick={() => rotate(-90)}
              icon="undo alternate"
              circular
            />
            <Button
              className="app-button"
              onClick={() => rotate(90)}
              icon="redo alternate"
              circular
            />

            <Button
              className="app-button"
              onClick={handleFileUpload}
              icon="save"
              circular
            />
          </Fragment>
        )}
      </div>
    </div>
  )
}
export default AvatarEditor

const dataURLToBlob = (dataURL) => {
  var BASE64_MARKER = ';base64,'
  var parts
  var contentType
  var raw
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(',')
    contentType = parts[0].split(':')[1]
    raw = parts[1]
    return new Blob([raw], { type: contentType })
  }

  parts = dataURL.split(BASE64_MARKER)
  contentType = parts[0].split(':')[1]
  raw = window.atob(parts[1])
  var rawLength = raw.length
  var uInt8Array = new Uint8Array(rawLength)
  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}
