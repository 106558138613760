//import
import React, { Fragment, useState } from "react"
import {
  NavLink,
  Route,
  Switch,
  Link,
  useRouteMatch,
  Redirect,
} from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import useLocalStorage from "react-use-localstorage"
import { Provider } from "use-http"

//UI
import "semantic-ui-css/semantic.min.css"
import "./App.css"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import {
  Menu,
  Container,
  Image,
  Icon,
  Modal,
  List,
  Sidebar,
  Grid,
} from "semantic-ui-react"

//App
import AdminExercises from "./views/admin/AdminExercises"
import AdminServices from "./views/admin/AdminServices"
import AdminSidebar from "./views/admin/components/AdminSidebar"
import Home from "./views/front/Home"
import Login from "./views/front/Login"
import Notifications from "./views/shared/Notifications"
import PTHome from "./views/pt/Patients"
import PTPatient from "./views/pt/Patient"
import PTPatients from "./views/pt/Patients"
import PTSidebar from "./views/pt/components/PTSidebar"
import PasswordChange from "./views/front/PasswordChange"
import PasswordForgot from "./views/front/PasswordForgot"
import PrivateRoute from "./views/shared/PrivateRoute"
import PublicSidebar from "./views/front/components/PublicSidebar"
import Service from "./views/front/Service"
import Signup from "./views/front/Signup"
import UserMessages from "./views/front/UserMessages"
import UserMilestones from "./views/front/UserMilestones"
import UserProfile from "./views/front/UserProfile"
import UserProtocol from "./views/front/UserProtocol"
import UserProtocols from "./views/front/UserProtocols"
import UserServices from "./views/front/UserServices"
import UserVideos from "./views/front/UserVideos"
import logo from "./images/logo-horiz-white.png"
import { Config } from "./Config"

// configure a lazy container
toast.configure()

function App() {
  //variables
  let serverURL = ""
  const masterToken = Config.masterToken

  if (process.env.NODE_ENV === "production") {
    serverURL = "https://server.app.smithptrun.com"
  } else {
    serverURL = window.location.origin.replace("3000", "9000")
  }

  //hooks
  const [SPTAToken] = useLocalStorage("SPTAToken")
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  const isLoginRoute = useRouteMatch({
    path: ["/login", "/pt/login"],
  })

  const isSignupRoute = useRouteMatch({
    path: "/signup",
  })

  const isUnauthenticatedRoute = useRouteMatch({
    path: ["/signup", "/password-resets"],
  })

  const isPtRoute = useRouteMatch({
    path: "/pt",
  })

  const isAdminRoute = useRouteMatch({
    path: "/admin",
  })

  //states
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [notificationsCount, setNotificationsCount] = useState(0)

  let getService = () => {
    let service = ""
    let url = window.location.href
    if (url.includes("service")) {
      let sr = url.split("service/")
      service = sr[1]
      if (service?.includes("/")) {
        sr = service.split("/")
        service = sr[0]
      }
    }
    return service
  }
  return (
    <Provider
      url={serverURL}
      options={{
        cachePolicy: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        interceptors: {
          // every time we make an http request, this will run 1st before the request is made
          // url, path and route are supplied to the interceptor
          // request options can be modified and must be returned
          request: async ({ options, url, path, route }) => {
            if (isUnauthenticatedRoute || route.includes("/password-resets")) {
              options.headers.Authorization = `Bearer ${masterToken}`
            } else if (route === "/auth") {
              let body = JSON.parse(options.body)
              options.headers.Authorization = `Basic ${body.hash}`
              delete body.hash
              options.body = JSON.stringify(body)
            } else if (route.indexOf("/password") !== -1) {
              let body = JSON.parse(options.body)
              options.headers.Authorization = `Basic ${body.hash}`
              delete body.hash
              options.body = JSON.stringify(body)
            } else {
              options.headers.Authorization = SPTAToken
                ? `Bearer ${SPTAToken}`
                : null
              if (route === "/users/upload") {
                delete options.headers["Content-Type"]
                //        options.headers['Content-Type'] = `multipart/form-data`
              }
            }
            return options
          },
        },
      }}
    >
      <Fragment>
        <Route path="*">
          <Menu fixed="top" className={`top-menu service-${getService()}`}>
            <Container>
              <Menu.Item
                position="left"
                style={{ marginLeft: "0px", width: "5px" }}
              >
                <Switch>
                  <Route
                    path={[
                      // chemins pour lesquels on doit pouvoir revenir en arriere
                      "/service/:service",
                      "/service/:service/userprotocols",
                      "/service/:service/userprotocols/:id",
                      "/service/:service/progresscards",
                      "/service/:service/uservideos",
                      "/messages/:protocolid?",
                      "/userservices",
                      "/profile",
                    ]}
                    render={(props) => (
                      <span
                        onClick={() => {
                          props.history.goBack()
                        }}
                        style={{ paddingLeft: "10px" }}
                      >
                        <Icon
                          name="arrow left"
                          size="large"
                          className="floated left  "
                          style={{ color: "white" }}
                        />
                      </span>
                    )}
                  />

                  <Route
                    path={[]} //acces par la home page
                    render={(props) => (
                      <Link
                        to={isPtRoute ? "/pt" : "/"}
                        style={{ paddingLeft: "5px" }}
                      >
                        <Icon
                          name="arrow left"
                          size="large"
                          className="floated left "
                          style={{ color: "grey" }}
                        />
                      </Link>
                    )}
                  />
                </Switch>

                {!isPtRoute && !isLoginRoute && !isSignupRoute && (
                  <Grid className="no-padding">
                    <Grid.Column only="computer">
                      <span>
                        <Icon
                          onClick={() => setVisible(!visible)}
                          name="bars"
                          size="large"
                          className="menu-btn"
                          style={{ color: "white", marginLeft: 5 }}
                        />
                      </span>
                    </Grid.Column>
                  </Grid>
                )}
              </Menu.Item>

              <Menu.Item as={Link} header to={isPtRoute ? "/pt" : "/"}>
                <Image className="app-logo" src={logo} />
              </Menu.Item>

              <Menu.Item position="right" style={{ position: "relative" }}>
                <Switch>
                  <Route path={["/admin", "/login", "/signup", "forgot"]}>
                    {/*permet de ne pas rendre la droite du menu burger menu pour admin*/}
                  </Route>
                  <Route path="*">
                    <Grid className="no-padding" style={{ marginTop: -30 }}>
                      <Grid.Column only="computer">
                        <span className="home-link">
                          <NavLink to="/" exact={true}>
                            <Icon
                              name="home"
                              size="large"
                              className="menu-btn "
                              style={{ marginRight: 10 }}
                            />
                          </NavLink>
                        </span>

                        <span className="messages-link">
                          <NavLink to={isPtRoute ? "/pt" : "/messages"}>
                            <Notifications
                              getCountResponsible={true}
                              setNotificationsCount={setNotificationsCount}
                              notificationsCount={notificationsCount}
                            />
                            <Icon
                              name="mail"
                              size="large"
                              className="menu-btn"
                            />
                          </NavLink>
                        </span>
                      </Grid.Column>
                    </Grid>
                  </Route>
                </Switch>
                <span className="phone-link" onClick={() => setOpen(true)}>
                  <Icon name="phone"></Icon>
                </span>
                <Modal
                  basic
                  closeIcon
                  size="mini"
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  open={open}
                >
                  <Modal.Content>
                    <div className="phone-list">
                      <List inverted divided relaxed>
                        <List.Item
                          as="a"
                          href="tel:+1815.893.9075"
                          onClick={() => setOpen(false)}
                        >
                          <Icon name="phone" />
                          <List.Content>
                            <List.Header>CRYSTAL LAKE</List.Header>
                            <List.Description>815.893.9075</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item
                          as="a"
                          href="tel:+1630.797.0327"
                          onClick={() => setOpen(false)}
                        >
                          <Icon name="phone" />
                          <List.Content>
                            <List.Header>ST. CHARLES </List.Header>
                            <List.Description>630.797.0327</List.Description>
                          </List.Content>
                        </List.Item>
                        <List.Item
                          as="a"
                          href="tel:+1630.797.0327"
                          onClick={() => setOpen(false)}
                        >
                          <Icon name="phone" />
                          <List.Content>
                            <List.Header>AQUATIC REHAB</List.Header>
                            <List.Description>815.449.4841</List.Description>
                          </List.Content>
                        </List.Item>
                      </List>
                    </div>
                  </Modal.Content>
                </Modal>
              </Menu.Item>
            </Container>
          </Menu>
        </Route>

        {!isLoginRoute && isTabletOrMobile && (
          <Menu
            fixed="bottom"
            className={`bottom-menu service-${getService()}`}
            widths={3}
          >
            <Menu.Item onClick={() => setVisible(!visible)}>
              <span>
                <Icon name="bars" size="large" className="menu-btn" />
              </span>
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to={isPtRoute ? "/pt" : isAdminRoute ? "/admin" : "/"}
              exact={true}
            >
              <span>
                <Icon name="home" size="large" className="menu-btn" />
              </span>
            </Menu.Item>
            <Menu.Item as={NavLink} to={isPtRoute ? "/pt" : "/messages"}>
              <span style={{ position: "relative" }}>
                <Notifications notificationsCount={notificationsCount} />
                <Icon name="mail" size="large" className="menu-btn" />
              </span>
            </Menu.Item>
          </Menu>
        )}

        <Switch>
          <Route path={["/login", "/pt/login", "/signup", "/forgot"]}></Route>
          <PrivateRoute path="/pt">
            <PTSidebar
              visible={isTabletOrMobile ? visible : true}
              setVisible={setVisible}
              isTabletOrMobile={isTabletOrMobile}
            />
          </PrivateRoute>
          <PrivateRoute path="/admin">
            <AdminSidebar
              visible={isTabletOrMobile ? visible : true}
              setVisible={setVisible}
              isTabletOrMobile={isTabletOrMobile}
            />
          </PrivateRoute>
          <PrivateRoute path="*">
            <PublicSidebar visible={visible} setVisible={setVisible} />
          </PrivateRoute>
        </Switch>
        <Switch>
          <Route exact path="/pt"></Route>
        </Switch>

        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={visible}>
            <Switch>
              <Route path="/admin">
                <div className={`main-content ${isLoginRoute ? "" : "admin"} `}>
                  <PrivateRoute path={["/admin/exercises", "/admin"]} exact>
                    <AdminExercises />
                  </PrivateRoute>
                  <PrivateRoute path={["/admin/services"]} exact>
                    <AdminServices />
                  </PrivateRoute>
                  <PrivateRoute path="*" exact>
                    <Redirect to={"/admin"} />
                  </PrivateRoute>
                </div>
              </Route>

              <Route path="/pt">
                <div
                  className={"main-content " + (isLoginRoute ? "" : "admin")}
                >
                  <Switch>
                    <Route path={["/pt/login"]} exact>
                      <Login />
                    </Route>
                    <PrivateRoute path={["/pt", "/pt/index.html"]} exact>
                      <PTHome />
                    </PrivateRoute>

                    <PrivateRoute path={["/pt/patients"]} exact>
                      <PTPatients />
                    </PrivateRoute>

                    <PrivateRoute path={["/pt/patient/:id/:expand?"]} exact>
                      <PTPatient />
                    </PrivateRoute>
                    <PrivateRoute path={["/pt/exercises"]} exact>
                      <AdminExercises />
                    </PrivateRoute>
                    <PrivateRoute path="*" exact>
                      <Redirect to={"/pt"} />
                    </PrivateRoute>
                  </Switch>
                </div>
              </Route>
              <Route path="*">
                <div className={`main-content service-${getService()}`}>
                  <Switch>
                    <Route path={["/login"]} exact>
                      <Login />
                    </Route>

                    <Route path={["/signup"]} exact>
                      <Signup />
                    </Route>
                    <Route path={["/password"]} exact>
                      <PasswordChange />
                    </Route>
                    <Route path={["/forgot/:token?"]} exact>
                      <PasswordForgot />
                    </Route>

                    <PrivateRoute path={["/", "/index.html"]} exact>
                      <Home />
                    </PrivateRoute>

                    <PrivateRoute path="/service/" exact>
                      <Home />
                    </PrivateRoute>
                    <PrivateRoute path="/service/:service" exact>
                      <Service />
                    </PrivateRoute>

                    <PrivateRoute path="/service/:service/userprotocols" exact>
                      <UserProtocols />
                    </PrivateRoute>

                    <PrivateRoute
                      path="/service/:service/userprotocols/:id"
                      exact
                    >
                      <UserProtocol />
                    </PrivateRoute>

                    <PrivateRoute path="/messages/:subject?" exact>
                      <UserMessages />
                    </PrivateRoute>

                    <PrivateRoute path="/profile" exact>
                      <UserProfile />
                    </PrivateRoute>

                    <PrivateRoute path="/userservices" exact>
                      <UserServices />
                    </PrivateRoute>

                    <PrivateRoute path="/service/:service/progresscards/" exact>
                      <UserMilestones />
                    </PrivateRoute>

                    <PrivateRoute path="/service/:service/uservideos/" exact>
                      <UserVideos />
                    </PrivateRoute>
                    <PrivateRoute path="*" exact>
                      <Redirect to={"/"} />
                    </PrivateRoute>
                  </Switch>
                </div>
              </Route>
            </Switch>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    </Provider>
  )
}

export default App
