//imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormState } from 'react-use-form-state'

//UI
import { Message, Segment, Form, Modal, Button } from 'semantic-ui-react'

function PatientProtocol(props) {
  // props
  const { saveAsTemplate, currentProtocol, setOpen, open, services } = props

  //hooks
  const { t } = useTranslation()
  const [formState, { text, textarea, raw }] = useFormState(currentProtocol)

  //states
  const [hasError, setHasError] = useState(false)

  //local functions
  const save = async () => {
    if (!formState.values.name) {
      setHasError(t('Name is mandatory'))
      return
    }
    if (!formState.values.service) {
      setHasError(t('Service is mandatory'))
      return
    }

    saveAsTemplate(formState.values)
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>{t('Template Protocol')}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form size="large">
            <Segment basic>
              <Form.Input
                label="Name"
                placeholder="Name"
                {...text('name')}
                required
              />{' '}
              <Form.TextArea
                label="Comments"
                placeholder="comments"
                {...textarea('comments')}
              />
              <Form.Select
                fluid
                label="Service"
                placeholder="Service"
                {...raw({
                  name: 'service',
                  validate: (value, values, event) => {
                    if (!value.trim()) {
                      return t('Service')
                    }
                  },
                })}
                onChange={(e, { value }) =>
                  formState.setField('service', value)
                }
                options={services}
                value={formState.values.service}
                required
              />
              {hasError && (
                <Message negative>
                  <p>{hasError}</p>
                </Message>
              )}
            </Segment>
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <Button
                circular
                icon={'close'}
                className="bottom-10 app-button"
                onClick={() => setOpen(false)}
                style={{ marginRight: 20 }}
              />

              <Button
                circular
                icon={'save'}
                className="bottom-10 app-button"
                onClick={() => save()}
              />
            </div>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}
export default PatientProtocol
