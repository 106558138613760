import React, { useEffect, useCallback, useState, useRef } from "react"
import {
  Icon,
  Grid,
  Segment,
  Image,
  Message,
  Container,
  Button,
} from "semantic-ui-react"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"
import { useSPTAUser } from "../../hooks/useSPTAUser"
import AppError from "../shared/AppError"

function Service() {
  //  const [therapist, setTherapist] = useState()
  // const [serviceLabel, setServiceLabel] = useState()
  const { t } = useTranslation()
  const { service } = useParams()

  const [serviceData, setServiceData] = useState()
  const [therapists, setTherapists] = useState()
  const [SPTAUser] = useSPTAUser()
  const { current: me } = useRef(SPTAUser)

  const { get, response, error, loading } = useFetch()

  const fetchService = useCallback(async () => {
    const serviceResponse = await get("/services/" + service)
    if (response.ok) {
      setServiceData(serviceResponse)
    }
  }, [get, response, service])

  const fetchTherapist = useCallback(async () => {
    const currentServiceTherapistsIds = me?.therapists?.filter(
      (x) => x.service === service
    )

    const therapistResponse = await Promise.all(
      currentServiceTherapistsIds.map((x) => get("/users/" + x.therapist))
    )

    if (response.ok) {
      const therapist = therapistResponse
        .filter((user) => user.role === "therapist")
        .sort((a, b) =>
          a.firstname > b.firstname ? 1 : a.firstname < b.firstname ? -1 : 0
        )
      setTherapists(therapist)
    }
  }, [me, get, response, service])

  useEffect(() => {
    fetchService()
  }, [fetchService])

  useEffect(() => {
    fetchTherapist()
  }, [fetchTherapist])
  return error ? (
    <AppError error={error} />
  ) : (
    <div className={`page service service-${service}`}>
      <div className="therapists">
        {therapists?.map((therapist) => (
          <div className="therapist" key={therapist.id}>
            <Image
              src={therapist.picture}
              size="small"
              circular
              centered
              style={{ display: "inline", margin: 10 }}
            />
            <div className="top-5">
              {t("{{therapist}}", {
                therapist:
                  therapist.firstname &&
                  therapist.firstname[0].toUpperCase() +
                    therapist.firstname.slice(1),
              })}
            </div>
          </div>
        ))}

        {!loading && therapists?.length === 0 && (
          <Container>
            <Message negative floating>
              <Message.Header>
                {t(`You have not selected your therapist yet.`)}
              </Message.Header>
              <Link to={`/userservices`}>
                <Button className="app-button top-10">
                  {" "}
                  {t(`Please select your therapist`)}
                </Button>
              </Link>
            </Message>
          </Container>
        )}
      </div>

      <Grid container columns={2}>
        <Grid.Row stretched className="top-20">
          <Grid.Column only="computer">
            <Segment className={"cover " + serviceData?.code}>
              <div>{t(serviceData?.name)}</div>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Grid columns={2}>
              <Grid.Column>
                <Link
                  to={
                    !!therapists && therapists?.length !== 0
                      ? `/service/${service}/userprotocols`
                      : false
                  }
                >
                  <Segment
                    className="bloc upload app-button"
                    disabled={!therapists || therapists?.length === 0}
                  >
                    <div className="bloc-content">
                      <Icon name="calendar alternate" size="huge" />
                      <div>{t("My Exercises")}</div>
                    </div>
                  </Segment>
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Link
                  to={
                    !!therapists && therapists?.length !== 0
                      ? `/service/${service}/uservideos`
                      : false
                  }
                >
                  <Segment
                    className="bloc upload app-button"
                    disabled={!therapists || therapists?.length === 0}
                  >
                    <div className="bloc-content">
                      <Icon name="cloud upload" size="huge" />
                      <div>{t("My Videos")}</div>
                    </div>
                  </Segment>
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Link
                  to={
                    !!therapists && therapists?.length !== 0
                      ? `/service/${service}/progresscards`
                      : false
                  }
                >
                  <Segment
                    className="bloc upload app-button"
                    disabled={!therapists || therapists?.length === 0}
                  >
                    <div className="bloc-content">
                      <Icon
                        name="clipboard list"
                        size="huge"
                        style={{ position: "relative" }}
                      ></Icon>

                      <div>{t(`Milestones`)}</div>
                    </div>
                  </Segment>
                </Link>
              </Grid.Column>
              <Grid.Column>
                <a href="https://www.smithptrun.com/physical-therapy-tips/">
                  <Segment className="bloc upload app-button">
                    <div className="bloc-content">
                      <Icon name="globe" size="huge" />
                      <div>{t(`Miscellaneous infos `)}</div>
                    </div>
                  </Segment>
                </a>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
export default Service
