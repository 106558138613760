//imports
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormState } from 'react-use-form-state'
import useFetch from 'use-http'

//ui
import { Button, Form, Dimmer, Loader, Message } from 'semantic-ui-react'
import { toast } from 'react-toastify'

//apps

const SPTAMessage = (props) => {
  //props
  const {
    updateMesages,
    to,
    therapistSelector,
    userTherapists,
    subject,
  } = props

  //hooks
  const { t } = useTranslation()
  const { get, post, response, loading, error } = useFetch()
  const [formState, { text, raw, textarea }] = useFormState({ subject })

  //states
  const [therapists, setTherapists] = useState([])
  const [hasError, setHasError] = useState(false)

  //useeffect functions
  const fetchTherapist = useCallback(async () => {
    const res = await get('/users/pts?role=therapist')
    const { rows } = res

    if (response.ok) {
      let options = []
      rows?.forEach((element) => {
        if (userTherapists?.includes(element.id)) {
          options.push({
            text: element.firstname,
            value: element.id,
          })
        }
      })
      setTherapists(options)
    }
  }, [userTherapists, get, response])

  useEffect(() => {
    fetchTherapist()
  }, [fetchTherapist])

  //local functions
  const save = async () => {
    let sendTo = to

    if (!to && userTherapists.length === 1) {
      sendTo = userTherapists[0]
    }

    if (!sendTo && !formState.values.therapist) {
      setHasError(t('Please choose your therapist'))
      return
    }

    if (!formState.values.subject) {
      setHasError(t('Please enter a subject'))
      return
    }
    if (!formState.values.comment) {
      setHasError(t('Please enter a message '))
      return
    }

    var payload = { ...formState.values }
    if (!sendTo) {
      sendTo = formState.values.therapist
    }
    payload = { ...payload, to: sendTo }

    const newMessage = await post('/messages', payload)
    if (response.ok) {
      formState.clear()
      toast.success(t('Message sent'))
      updateMesages(newMessage)
    } else {
      toast.error(t('Message not sent'))
    }
  }

  return (
    <Form size="large">
      <Dimmer active={loading} inverted>
        <Loader inverted />
      </Dimmer>
      {!therapistSelector && (
        <div style={{ textAlign: 'right' }}>
          <Button.Group
            basic
            size="small"
            style={{
              // float: 'right',
              marginLeft: 5,
            }}
          >
            <Button icon="send" onClick={() => save()} />
          </Button.Group>
        </div>
      )}
      {(error || hasError) && (
        <Message negative>
          <p>
            {response.data?.message}
            {hasError}
          </p>
        </Message>
      )}
      {therapistSelector && userTherapists.length > 1 && (
        <Form.Select
          fluid
          label="Therapist"
          placeholder="therapist"
          {...raw({
            name: 'therapist',
          })}
          onChange={(e, { value }) => formState.setField('therapist', value)}
          options={therapists}
          value={formState.values.therapist}
          required
        />
      )}
      <Form.Input
        fluid
        label="Subject"
        placeholder="subject"
        {...text('subject')}
        required
      />{' '}
      <Form.TextArea
        label="Message"
        placeholder="Message"
        {...textarea('comment')}
        required
        rows={5}
      />
      {therapistSelector && (
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          <Button
            circular
            icon={'send'}
            className="bottom-10 app-button"
            onClick={() => save()}
          />
        </div>
      )}
    </Form>
  )
}

export default SPTAMessage
