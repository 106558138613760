//imports
import React, { useState } from "react"
import daysjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useFormState } from "react-use-form-state"
import useFetch from "use-http"

//UI
import { Message, Button, Form, Card } from "semantic-ui-react"
import { toast } from "react-toastify"

function PatientNotes(props) {
  // props
  const { patient } = props

  //hooks
  const { t } = useTranslation()
  const [formState, { textarea }] = useFormState()

  const { patch, response, error } = useFetch()

  //states
  const [hasError, setHasError] = useState(false)
  const [isCommentsEdited, setIsCommentsEdited] = useState(false)
  const [comments, setComments] = useState(patient.comments)

  //use effect functions

  //local functions
  const save = async () => {
    if (!formState.values.comments) {
      setHasError(t("Please enter your comment "))
      return
    }

    var payload = { ...formState.values }
    let res = await patch(`/users/${patient.id}/comments`, payload)
    if (response.ok) {
      toast.success(t("Comment succesfully saved"))
      setIsCommentsEdited(false)
      setComments(res.comments)
    } else {
      toast.error(t("Comment was not saved"))
    }
  }
  const toggleEdit = (isCommentsEdited) => {
    if (!isCommentsEdited) {
      formState.setField("comments", comments?.text || patient.comments?.text)
    }
    setIsCommentsEdited(!isCommentsEdited)
  }

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          {t("Comments")}{" "}
          <Button.Group basic size="small" floated="right">
            <Button
              basic
              icon={isCommentsEdited ? "close" : "edit"}
              onClick={() => toggleEdit(isCommentsEdited)}
            />
            {isCommentsEdited && (
              <Button basic icon={"save"} onClick={() => save()} />
            )}
          </Button.Group>
        </Card.Header>
        <Card.Meta className="top-5" textAlign="right">
          {comments?.updatedAt && (
            <span className="date">
              Updated :{daysjs(comments.updatedAt).format("MMM DD")} by{" "}
              {comments.updatedBy.firstname}
            </span>
          )}
        </Card.Meta>
        <Card.Description className="top-20">
          {!isCommentsEdited && (comments?.text || patient.comments?.text)}
          {isCommentsEdited && (
            <Form>
              <Form.TextArea
                placeholder="comments"
                {...textarea("comments")}
                rows={10}
              />
            </Form>
          )}
          {(error || hasError) && (
            <Message negative>
              <p>
                {response.data?.message}
                {hasError}
              </p>
            </Message>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default PatientNotes
