//imports
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Redirect, useLocation, useHistory, Link } from "react-router-dom"
import { useFormState } from "react-use-form-state"
import useLocalStorage from "react-use-localstorage"
import useFetch from "use-http"

//UI
import {
  Button,
  Form,
  Grid,
  Message,
  Segment,
  Container,
  Modal,
} from "semantic-ui-react"

//App
import { useSPTAUser } from "../../hooks/useSPTAUser"
import Loading from "../shared/Loading"
import { Config } from "../../Config"

const LoginForm = () => {
  // props

  //hooks
  const { t } = useTranslation()
  const [formState, { email, password }] = useFormState()
  const [SPTAToken, setSPTAToken] = useLocalStorage("SPTAToken")
  const [, updateSPTAUser] = useSPTAUser()
  const location = useLocation()
  const history = useHistory()
  const { post, response, loading, error } = useFetch()

  //states
  const [isLoggedIn] = useState(!!SPTAToken)
  const [open, setOpen] = useState(false)

  //use effect functions

  // local variables
  const from = location.pathname === "/pt,/login" ? "/pt" : "/"
  const access_token = Config.masterToken

  //local functions

  const login = async (e) => {
    const { email, password } = formState.values
    let tok = email + ":" + password
    let hash = btoa(tok)
    const { token, user } = await post("/auth", { access_token, hash })

    if (response.ok) {
      setSPTAToken(token)
      updateSPTAUser(user)

      if (password === Config.defaultPatientPassword) {
        setOpen(true)
        return
      }

      if (user.role === "therapist") {
        window.location = "/pt"

        //    history.push('/admin')
      } else if (user.role === "admin") {
        window.location = "/admin"
      } else {
        window.location = "/"
      }
    }
  }

  return (
    <div className="page login ">
      {isLoggedIn && <Redirect to={from} />}
      <Container fluid style={{ padding: "1em" }}>
        <Grid
          textAlign="center"
          style={{ height: "80vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 350 }}>
            <Form size="large" onSubmit={login}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  {...email("email")}
                  required
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  {...password("password")}
                />
                {error && (
                  <Message negative>{t("Authentication failed")}</Message>
                )}
                <Button type="submit" fluid size="large" className="app-button">
                  {loading ? <Loading /> : t("Login")}
                </Button>
              </Segment>
            </Form>
            <Message>
              {t("New to us?")} <Link to="/signup">{t("Sign Up")}</Link>
            </Message>
            <Link style={{ color: "white" }} to="/forgot">
              {t("Forgotten password")}{" "}
            </Link>
          </Grid.Column>
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
          >
            <Modal.Header>{t("Default Password")}</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <p>
                  {t(
                    "You are using the default passord, please change to a personal one"
                  )}
                </p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Ok"
                labelPosition="right"
                icon="checkmark"
                onClick={() => history.push("/password")}
                negative
              />
            </Modal.Actions>
          </Modal>
        </Grid>
      </Container>
    </div>
  )
}

export default LoginForm
