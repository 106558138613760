import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Segment, Button } from 'semantic-ui-react'
import oops from '../../images/error.png'

function AppError(props) {
  const { t } = useTranslation()

  return (
    <div>
      <Image
        src={oops}
        size="medium"
        circular
        centered
        style={{ display: 'block', margin: '20px auto' }}
      />
      <Segment
        textAlign="center"
        basic
        className="blue-smith"
        style={{ marginTop: -115, fontSize: '1.2rem' }}
      >
        <div>
          {props.message ? props.message : t('Something went wrong ...')}
        </div>{' '}
        <Button
          inverted
          className="blue-smith top-10"
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('retry')}
        </Button>{' '}
      </Segment>
    </div>
  )
}
export default AppError
