//imports
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"

//UI
import { Feed, Icon, Dimmer, Loader, Image } from "semantic-ui-react"
import { toast } from "react-toastify"
import logo192 from "../../images/logo192.png"

//app
import { useSPTAUser } from "../../hooks/useSPTAUser"
import { useHistory } from "react-router-dom"

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

function MessagesList(props) {
  //props
  const {
    messages: initialMessages,
    nextMessages,
    showMore,
    messagesLoading,
    maxHeight = "",
  } = props

  //hooks
  const { t } = useTranslation()
  const [SPTAUser] = useSPTAUser()
  const { patch, response } = useFetch()
  const history = useHistory()

  //states
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState([])

  //useEffect Functions
  useEffect(() => {
    setMessages(initialMessages)
  }, [initialMessages])

  //local functions
  const onMessageClicked = async (message) => {
    await toggleRead({ id: message.id, read: message.read })
    displayPatient({ patientId: message.patient.id, expand: message.type })
  }
  const toggleRead = async ({ id, read }) => {
    //if read already do nothing
    //if (read) return
    read = !read
    let tmpLoading = [...loading]
    tmpLoading.push(id)
    setLoading(tmpLoading)
    await patch(`/messages/read/${id}`, { read })
    if (response.ok) {
      //setMessagesCount(count)

      let tmpArr = [...messages]
      let updatedItem = tmpArr.find((element) => {
        return element.id === id
      })
      // Modify object property
      updatedItem.read = read
      setMessages(tmpArr)
      tmpLoading = tmpLoading.filter((e) => "" + e !== "" + id)
      setLoading(tmpLoading)
    } else {
      toast.error(t("Message status not updated"))
    }
  }

  const displayPatient = ({ patientId, expand }) => {
    history.push(`/pt/patient/${patientId}/${expand}`)
  }

  return (
    <Feed className="message-feed" style={{ maxHeight, minHeight: 65 }}>
      {messages?.length === 0 && !messagesLoading && (
        <Feed.Event>
          <Feed.Content>{t("No message to display yet...")}</Feed.Content>
        </Feed.Event>
      )}
      {messages.map((message) => (
        <Feed.Event
          className={"" + message.from.id === "" + SPTAUser.id ? `self` : ``}
          key={message.id}
          style={{
            opacity:
              message.read || "" + message.from.id === "" + SPTAUser.id
                ? 0.6
                : 1,
            cursor: "pointer",
            position: "relative",
          }}
        >
          <Dimmer active={loading.includes(message.id)} inverted>
            <Loader inverted />
          </Dimmer>
          <Feed.Label>
            <Image src={message.from === 0 ? logo192 : message.from.picture} />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary
              onClick={() =>
                "" + message.from.id === "" + SPTAUser.id
                  ? null
                  : onMessageClicked(message)
              }
            >
              {message.subject}
              <Feed.Date>
                <span className="author">
                  {message.from.id === 0
                    ? "SPT App"
                    : "" + message.from.id !== "" + SPTAUser.id
                    ? message.from.firstname + " " + message.from.lastname
                    : `You`}
                </span>
                - {dayjs(message.createdAt).fromNow()}
              </Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>{message.comment}</Feed.Extra>
          </Feed.Content>
        </Feed.Event>
      ))}
      {messagesLoading && (
        <Feed.Event
          style={{
            justifyContent: "center",
            position: "relative",
            minHeight: 30,
          }}
        >
          <Feed.Content>
            <Dimmer active={true} inverted>
              <Loader inverted />
            </Dimmer>
          </Feed.Content>
        </Feed.Event>
      )}
      {showMore && (
        <Feed.Event
          style={{ justifyContent: "center", paddingBottom: "0.1rem" }}
        >
          <Icon
            name="chevron circle down"
            size="big"
            onClick={() => nextMessages()}
            color="grey"
          />
        </Feed.Event>
      )}
    </Feed>
  )
}

export default MessagesList
