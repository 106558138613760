//imports
import React, { useCallback, useState, useEffect } from "react"
import useFetch from "use-http"
import { useTranslation } from "react-i18next"

//UI
import {
  Container,
  Header,
  List,
  Checkbox,
  Form,
  Loader,
} from "semantic-ui-react"
//App
import AppError from "../shared/AppError"

function AdminServices() {
  // local variables

  // props

  //hooks
  const { t } = useTranslation()
  const { put, get, response, loading, error } = useFetch()

  //states
  const [services, setServices] = useState([])
  const [therapists, setTherapists] = useState([])
  const [updating, setUpdating] = useState()

  //use effect functions

  const fetchServices = useCallback(async () => {
    const res = await get("/services?sort=name")
    const { rows } = res

    if (response.ok) {
      setServices(rows)
    }
  }, [get, response, setServices])
  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  const fetchTherapist = useCallback(async () => {
    const res = await get("/users/?role=therapist")
    const { rows } = res
    if (response.ok) {
      setTherapists(rows)
    }
  }, [get, response, setTherapists])

  useEffect(() => {
    fetchTherapist()
  }, [fetchTherapist])

  //local functions
  const updateService = async (service) => {
    await put("/services/" + service.id, service)
    if (response.ok) {
      // setTherapists(rows)
    }
  }

  const toggleTherapist = (serviceId, therapistId) => {
    let newServices = services.map((x) => x)
    let service = newServices.find((service) => service.id === serviceId)
    if (service) {
      if (service.therapists?.includes(therapistId)) {
        let therapists = service.therapists.filter((x) => x !== therapistId)
        service.therapists = therapists
      } else {
        let therapists = service.therapists
        therapists.push(therapistId)
      }
    }
    setServices(newServices)
    setUpdating(therapistId + serviceId)
    updateService(service)
  }

  const isInService = (serviceId, therapistId) => {
    let service = services.find((service) => service.id === serviceId)
    if (service) {
      return service.therapists?.includes(therapistId) ? true : false
    }
  }

  return error ? (
    <AppError error={error} />
  ) : (
    <div className=" page admin-services">
      <Container style={{ maxWidth: "none" }}>
        <Header as="h2"> {t("Services at Smith PT")}</Header>
        <Form>
          <List divided selection>
            {services?.map((service) => (
              <List.Item key={service.id}>
                <List.Content>
                  <List.Header className={"bottom-5"}>
                    {service.name}
                  </List.Header>

                  <List.Description>
                    <Form.Group widths="equal">
                      {therapists.map((therapist) => (
                        <Form.Field key={therapist.id}>
                          {loading && updating === therapist.id + service.id ? (
                            <Loader active inline size="mini" />
                          ) : (
                            <Checkbox
                              label={`${therapist.firstname} ${therapist.lastname[0]} `}
                              checked={isInService(service.id, therapist.id)}
                              onChange={() =>
                                toggleTherapist(service.id, therapist.id)
                              }
                            />
                          )}
                        </Form.Field>
                      ))}
                    </Form.Group>
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>{" "}
        </Form>
      </Container>
    </div>
  )
}
export default AdminServices
