//imports
import React, { useCallback, useEffect } from 'react'
import useFetch from 'use-http'

//UI
import { Label } from 'semantic-ui-react'

function Loading(props) {
  // props
  const {
    getCountResponsible,
    notificationsCount,
    setNotificationsCount,
  } = props

  //hooks
  const { get, response } = useFetch()

  //states

  //use effect functions

  const fetchNotificationsCount = useCallback(async () => {
    if (!getCountResponsible) return
    const res = await get('/messages/count')
    if (response.ok) {
      if (res) {
        const { count } = res
        setNotificationsCount(count)
      }
    }
  }, [get, response, getCountResponsible, setNotificationsCount])

  //Récupération à fréquence donnée
  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotificationsCount()
    }, 2 * 60 * 1000)
    return () => clearInterval(interval)
  }, [fetchNotificationsCount])

  //Récupération au chargement initial
  useEffect(() => {
    fetchNotificationsCount()
  }, [fetchNotificationsCount])

  return (
    notificationsCount !== 0 && (
      <Label color="teal" floating circular className="notification">
        {notificationsCount}
      </Label>
    )
  )
}
export default Loading
