//import useLocalStorage from 'react-use-localstorage'

import { updateSPTAUser } from '../services/api'
export function useSPTAUser() {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const storedValue = JSON.parse(window.localStorage.getItem('SPTAUser'))

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    updateSPTAUser(value)
  }

  return [storedValue, setValue]
}
