import React from 'react'
import { Button } from 'semantic-ui-react'

function ButtonLoading(props) {
  return (
    <Button
      className="app-button"
      onClick={props.onClick}
      icon="circle notch"
      loading
      circular
    />
  )
}
export default ButtonLoading
