//imports
import React, { useState, useEffect, useCallback, useRef } from "react"
import { useRouteMatch, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"

//UI
import {
  Container,
  Grid,
  Segment,
  Header,
  Card,
  Image,
  Menu,
  Input,
  Dimmer,
  Loader,
  Dropdown,
  Message,
  Icon,
  Ref,
} from "semantic-ui-react"

//App
import AppError from "../shared/AppError"
import MessagesList from "../shared/MessagesList"
import Signup from "../front/Signup"

function Patients() {
  //local vars
  const limit = 50
  const messagesLimit = 20
  const parentRef = useRef(null)

  //hooks
  const { t } = useTranslation()
  const { get, response, loading, error } = useFetch()

  //states
  const [isNew, setIsNew] = useState(false)
  const [patientsArray, setPatientsArray] = useState([])
  const [patientsLoading, setPatientsLoading] = useState()
  const [countByLetter, setCountByLetter] = useState([])
  const [count, setCount] = useState([])
  const [letterFilter, setLetterFilter] = useState(null)
  const [containsFilter, setContainsFilter] = useState()
  const [meFilter, setMeFilter] = useState("my")
  const [needle, setNeedle] = useState("")
  const [messages, setMessages] = useState([])
  const [messagesCount, setMessagesCount] = useState([])
  const [messagesPage, setMessagesPage] = useState(1)
  const [messagesLoading, setMessagesLoading] = useState()
  const [messagesListHeight, setMessagesListHeight] = useState()

  //use effect functions
  const fetchPatients = useCallback(async () => {
    setPatientsLoading(true)
    const res = await get(
      "/users/patients/" +
        (meFilter === "my" ? "my" : "") +
        "?sort=lastname&limit=" +
        limit +
        (letterFilter ? "&letter=^" + letterFilter : "") +
        (containsFilter ? "&contains=" + containsFilter : "")
    )

    const { count, rows, countByLetter } = res
    if (response.ok) {
      setPatientsArray(rows)
      setCount(count)
      setCountByLetter(countByLetter)
      setPatientsLoading(false)
    }
  }, [meFilter, letterFilter, containsFilter, get, response])

  useEffect(() => {
    fetchPatients()
  }, [fetchPatients])

  const fetchMessages = useCallback(async () => {
    setMessagesLoading(true)
    const res = await get(
      `/messages/new?&sort=-createdAt&limit=${messagesLimit}&page=${messagesPage}`
    )

    const { count, rows } = res

    if (response.ok) {
      setMessages([...rows])
      setMessagesCount(count)
      setMessagesLoading(false)
    }
  }, [get, messagesPage, response.ok])

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  useEffect(() => {
    if (parentRef.current) {
      let parentHeight = parentRef.current.offsetHeight
      setMessagesListHeight(parentHeight + 200)
    }
  }, [parentRef])

  //local
  const nextMessages = () => {
    setMessagesPage(messagesPage + 1)
  }
  const isPatientRoute = useRouteMatch({
    path: "/pt/patients",
    exact: true,
  })
  const filterOnFirstLetter = (letter) => {
    if (!letter) {
      setLetterFilter(null)
    } else {
      setLetterFilter(letter)
    }
  }
  const resetAllFilters = () => {
    setContainsFilter(null)
    setNeedle("")
    setLetterFilter(null)
  }
  const handleSearchChange = (e, { value }) => {
    if (!value) {
      setNeedle(null)
      setContainsFilter()
    } else {
      setNeedle(value)
      if (value.length >= 3) {
        setContainsFilter(value)
      }
    }
  }
  const handleMeFilter = (e, { value }) => {
    setMeFilter(value)
  }

  const postRegister = () => {
    setIsNew(false)

    fetchPatients()
  }
  return error ? (
    <AppError error={error} />
  ) : (
    <div className="page admin-patients ">
      <Container fluid style={{ padding: "1em" }}>
        <Header as="h2">
          {isPatientRoute ? t("Patients") : t("Dashboard")}
        </Header>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column
              mobile={isPatientRoute ? 16 : 8}
              tablet={isPatientRoute ? 16 : 10}
              computer={isPatientRoute ? 16 : 10}
              largeScreen={isPatientRoute ? 16 : 12}
            >
              <Segment>
                <Header>
                  <Dropdown
                    inline
                    options={[
                      {
                        text: t("My Patients"),
                        value: "my",
                      },
                      {
                        text: t("All Patients"),
                        value: "all",
                      },
                    ]}
                    onChange={handleMeFilter}
                    value={meFilter}
                  />
                  <Icon
                    name={!isNew ? "add circle" : "close"}
                    className={"blue-smith"}
                    style={{
                      display: "inline-block",
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsNew(!isNew)}
                  />
                </Header>
                <Segment basic textAlign="center" style={{ overflow: "auto" }}>
                  <Menu pagination className="alpha-nav">
                    <Menu.Item
                      style={{ margin: "0px 2px 0px 0px", padding: 0 }}
                    >
                      <Input
                        icon="search"
                        onChange={handleSearchChange}
                        value={needle}
                      />
                    </Menu.Item>

                    <Menu.Item
                      name={"all"}
                      active={!letterFilter && !containsFilter}
                      onClick={() => resetAllFilters(null)}
                    />
                    {countByLetter.map((x) => (
                      <Menu.Item
                        key={x.letter}
                        name={x.letter}
                        active={letterFilter === x.letter}
                        disabled={!x.count}
                        onClick={() => filterOnFirstLetter(x.letter)}
                      />
                    ))}
                  </Menu>
                </Segment>
                {!patientsArray.length && !loading && (
                  <Segment className={"blue-smith"} textAlign="center">
                    {" "}
                    {t("No patient to display")}
                  </Segment>
                )}
                <Message icon compact hidden={count < limit}>
                  <Icon name="exclamation" />
                  <Message.Content>
                    <Message.Header>
                      {t("Only {{limit}} patients of {{count}} displayed ", {
                        limit,
                        count,
                      })}
                    </Message.Header>
                    {t("Please Refine your filters ", {})}
                  </Message.Content>
                </Message>
                {isNew ? (
                  <Signup isEmbedded={true} postRegister={postRegister} />
                ) : (
                  <Card.Group style={{ position: "relative" }}>
                    <Dimmer active={patientsLoading} inverted>
                      <Loader inverted />
                    </Dimmer>

                    {patientsArray.sort().map((currentPatient, i) => (
                      <Card
                        key={i}
                        color={currentPatient.isActive ? null : "red"}
                      >
                        <Card.Content>
                          <Image
                            floated="right"
                            size="tiny"
                            src={currentPatient.picture}
                          />
                          <Card.Header>
                            <Link to={`/pt/patient/${currentPatient.id}`}>
                              {currentPatient.firstname}{" "}
                              {currentPatient.lastname}
                            </Link>
                          </Card.Header>
                          {!currentPatient.isActive && (
                            <Card.Meta>
                              <span style={{ color: "red" }}>
                                {t("archived")}
                              </span>
                            </Card.Meta>
                          )}
                          {/*

                        <Card.Meta>Friends of Elliot</Card.Meta>
                        <Card.Description>
                          <strong>{daysjs().format('MMM DD YYYY')}</strong>
                          <br />
                          Current Protocol : Lunges , squats, plank
                        </Card.Description>
												*/}
                          {currentPatient.therapists
                            .map((the) => the.therapist.role)
                            .includes("user") ? (
                            <Icon name="warning" color="red" />
                          ) : (
                            ""
                          )}
                        </Card.Content>
                      </Card>
                    ))}
                  </Card.Group>
                )}{" "}
              </Segment>
            </Grid.Column>
            {!isPatientRoute && (
              <Grid.Column mobile={8} tablet={6} computer={6} largeScreen={4}>
                <Ref innerRef={parentRef}>
                  <Segment>
                    <Header>{t("Last Messages")}</Header>
                    <MessagesList
                      messages={messages}
                      nextMessages={nextMessages}
                      showMore={messagesLimit * messagesPage < messagesCount}
                      messagesLoading={messagesLoading}
                      maxHeight={messagesListHeight}
                    />
                  </Segment>
                </Ref>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

//https://loremflickr.com/320/240/face
export default Patients
