//imports
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
//UI
import {
  Menu,
  Image,
  Icon,
  Sidebar,
  Segment,
  Button,
  Modal,
} from "semantic-ui-react"
import AvatarEditor from "../../shared/AvatarEditor"
import useLogout from "../../../hooks/useLogout"
import { useSPTAUser } from "../../../hooks/useSPTAUser"

function PublicSidebar(props) {
  //hooks
  const { t } = useTranslation()

  //states
  const [SPTAUser] = useSPTAUser()
  const [open, setOpen] = useState(false)

  //local functions
  const logout = useLogout()
  const doLogout = (_) => {
    props.setVisible(false)
    logout()
  }
  const toggleOpenAvatarEditor = () => {
    setOpen(!open)
  }

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      onHide={() => props.setVisible(false)}
      vertical
      visible={props.visible}
      width="thin"
    >
      <div className="close">
        <Icon name="close" onClick={() => props.setVisible(false)} />
      </div>

      <div style={{ position: "relative" }}>
        <Image
          src={
            SPTAUser?.picture +
            (SPTAUser?.picture?.indexOf("gravatar") === -1
              ? "?cache=" + SPTAUser.updatedAt
              : "")
          }
          centered
          size="medium"
        />
        <span onClick={toggleOpenAvatarEditor}>
          <Button
            circular
            icon="pencil"
            className={" app-button "}
            style={{
              position: "absolute",
              right: 10,
              bottom: -10,
              zIndex: 10,
            }}
          />
        </span>
      </div>
      <Segment basic textAlign="center" className="username">
        {`${SPTAUser.firstname} ${SPTAUser.lastname}`}
      </Segment>

      {SPTAUser.role === "therapist" && (
        <Menu.Item as={Link} to="/pt" onClick={() => props.setVisible(false)}>
          <Icon name="user circle" />
          {t("Pt access")}
        </Menu.Item>
      )}
      <Menu.Item
        as={Link}
        to="/profile"
        onClick={() => props.setVisible(false)}
      >
        <Icon name="user" />
        {t("Profile")}
      </Menu.Item>

      <Menu.Item
        as={Link}
        to="/userservices"
        onClick={() => props.setVisible(false)}
      >
        <Icon name="bookmark" />
        {t("Services")}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/password"
        onClick={() => props.setVisible(false)}
      >
        <Icon name="lock" />
        {t("Change Password")}
      </Menu.Item>
      <Menu.Item as={Link} to="/login" onClick={() => doLogout()}>
        <Icon name="sign-out" flipped="horizontally" />
        {t("Log out")}
      </Menu.Item>
      <Modal
        style={{ textAlign: "center" }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        basic
      >
        <Modal.Content>
          <Modal.Description>
            <AvatarEditor
              image={
                SPTAUser.picture +
                (SPTAUser.picture?.indexOf("gravatar") === -1
                  ? "?cache=" + SPTAUser.updatedAt
                  : "")
              }
              setOpen={setOpen}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Sidebar>
  )
}

export default PublicSidebar
