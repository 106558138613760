// imports
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useDropzone } from "react-dropzone"
import useFetch from "use-http"
import { toast } from "react-toastify"
import { Fragment } from "react"

//UI
import {
  Container,
  Form,
  Button,
  Icon,
  Modal,
  Progress,
} from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
//App
import { useSPTAUser } from "../../hooks/useSPTAUser"
import { handleUpload } from "../../services/api"

function UserMediaUpload(props) {
  // props
  const { postUpload, setOpen } = props
  //hooks
  const { t } = useTranslation()
  const params = useParams()
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".mov, .MOV, .mp4, .MP4 ",
    //accept: 'video/*',
  })
  const { post, response, loading } = useFetch()
  const [SPTAUser] = useSPTAUser()

  //states
  const [title, setTitle] = useState("")
  const [progressPct, setProgressPct] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  // local variables
  let { service } = params

  const createVideo = async () => {
    setIsLoading(true)
    let res = await handleUpload(acceptedFiles, setProgressPct, post, response)
    if (!res.ok) {
      toast.error(
        t("Could not upload file {{filename}}", { filename: res.error })
      )
      setIsLoading(false)
      return
    }

    let files = res.files
    if (files) {
      let payload = {
        user: SPTAUser.id,
        files,
        title,
        service,
        therapists: [...new Set(SPTAUser.therapists.map((x) => x.therapist))],
      }
      await post("/userMedias", payload)

      if (response.ok) {
        setIsLoading(false)
        toast.success(t("Videos successfully registered"))
        postUpload()
        setOpen(false)
      } else {
        toast.error(t("Could not register videos "))
        setIsLoading(false)
        return false
      }
    }
  }

  return (
    <Fragment>
      <Modal.Header>{title || t("New video")}</Modal.Header>
      <Modal.Content>
        <Form className="upload-form">
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Title"
              placeholder="Title"
              value={title || ""}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>

          <Container textAlign="center">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Icon name="cloud upload" size="massive" />
              <p>{t("Click to select your video file")}</p>
            </div>
            <ul style={{ listStyleType: "none" }}>
              {acceptedFiles.map((file, i) => {
                let filekey = file.name.toLowerCase().split(".").join("")
                let pct = progressPct[filekey]
                return (
                  <li key={file.path}>
                    {file.path} - {file.size} bytes
                    {pct && (
                      <Fragment>
                        <div>{pct + "%"}</div>
                        <Progress percent={pct} size="tiny" />
                      </Fragment>
                    )}
                  </li>
                )
              })}
            </ul>
          </Container>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          circular
          icon="close"
          className={"bottom-10 app-button"}
          onClick={() => setOpen(false)}
        />
        {!loading && (
          <Button
            circular
            icon="send"
            className={"bottom-10 app-button"}
            onClick={() => createVideo()}
          />
        )}
        {loading && isLoading && (
          <Button
            circular
            loading
            icon="spinner"
            className={"bottom-10 app-button"}
          />
        )}
      </Modal.Actions>
    </Fragment>
  )
}
export default UserMediaUpload
