//imports
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import useFetch from "use-http"
import dayjs from "dayjs"

//UI
import { Icon, Card } from "semantic-ui-react"
import { Fade } from "react-awesome-reveal"
import SPTAMessage from "../../shared/Message"

//app
import { useSPTAUser } from "../../../hooks/useSPTAUser"
import MessagesList from "../../shared/MessagesList"

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

function PatientMessages(props) {
  //props
  const { expand, expandBloc, patientId } = props

  //local vars
  const messagesLimit = 20
  const parentRef = useRef(null)
  //hooks
  const { t } = useTranslation()
  const { get, response } = useFetch()
  const [SPTAUser] = useSPTAUser()

  //states
  const [isNew, setIsNew] = useState(false)
  const [messages, setMessages] = useState([])
  const [messagesCount, setMessagesCount] = useState([])
  const [messagesPage, setMessagesPage] = useState(1)
  let [messagesLoading, setMessagesLoading] = useState()
  let [messagesListHeight, setMessagesListHeight] = useState()

  //ue effect functions
  const fetchMessages = useCallback(async () => {
    setMessagesLoading(true)
    const res = await get(
      `/messages?pt=${SPTAUser.id}&patient=${patientId}&sort=-createdAt&limit=${messagesLimit}&page=${messagesPage}`
    )
    const { count, rows } = res || {}

    if (response.ok) {
      setMessages([...rows])
      setMessagesCount(count)
      setMessagesLoading(false)
    }
  }, [SPTAUser.id, get, messagesPage, patientId, response.ok])

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages])

  useEffect(() => {
    if (parentRef.current) {
      let parentHeight = parentRef.current.offsetHeight
      if (expand) {
        setMessagesListHeight("none")
      } else {
        setMessagesListHeight(parentHeight - 50)
      }
    }
  }, [expand, parentRef])

  //local functions
  //local
  const nextMessages = () => {
    setMessagesPage(messagesPage + 1)
  }

  const createNew = () => {
    setIsNew(true)
  }

  const updateMesages = (msg) => {
    setMessages([msg, ...messages])
    setMessagesCount(messagesCount + 1)
    setIsNew(false)
  }

  const cancel = () => {
    setIsNew(false)
  }
  return (
    <div ref={parentRef}>
      <Card fluid>
        <Card.Content className="header-container">
          <Icon
            onClick={() => (expand ? expandBloc() : expandBloc("messages"))}
            name={expand ? "compress" : "expand"}
            style={{
              float: "right",
              cursor: "pointer",
            }}
          />

          {!isNew && (
            <Icon
              onClick={() => createNew()}
              name={"add"}
              style={{
                float: "right",
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
          )}

          {isNew && (
            <Fragment>
              <Icon
                onClick={() => cancel()}
                name={"close"}
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginRight: "15px",
                }}
              />
            </Fragment>
          )}
          <Card.Header
            onClick={() => (expand ? expandBloc() : expandBloc("messages"))}
            style={{ cursor: "pointer" }}
          >
            {t("Messages")}
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {isNew && (
            <div style={{ overflow: "hidden" }}>
              <Fade>
                <SPTAMessage updateMesages={updateMesages} to={patientId} />
              </Fade>
            </div>
          )}

          <Card.Description className="user-messages">
            <MessagesList
              messages={messages}
              nextMessages={nextMessages}
              showMore={messagesLimit * messagesPage < messagesCount}
              messagesLoading={messagesLoading}
              maxHeight={messagesListHeight}
            />
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  )
}

export default PatientMessages
