//imports
import React, { useCallback, useState, useEffect } from "react"
import useFetch from "use-http"
import { useTranslation } from "react-i18next"
//UI
import { Container, Header } from "semantic-ui-react"

//App
import { updateSPTAUser } from "../../services/api"
import UserServicesSelector from "../shared/UserServicesSelector"
import AppError from "../shared/AppError"

function UserServices() {
  // local variables

  //hooks
  const { t } = useTranslation()
  const { get, response, error } = useFetch()

  //states
  const [user, setUser] = useState({})
  const [hasError, setHasError] = useState()
  const [services, setServices] = useState([])

  //use effect functions
  const fetchServices = useCallback(async () => {
    const res = await get("/services?sort=name&expand=true")
    const { rows } = res
    if (response.ok) {
      setServices(rows)
    }
  }, [get, response, setServices])

  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  const getCurrentUser = useCallback(async () => {
    const me = await get("/users/me")
    if (response.ok) {
      setUser(me)
      updateSPTAUser(me)
    }
  }, [get, response])

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  return error || hasError ? (
    <AppError error={error} />
  ) : (
    <div className=" page user-services">
      <Container>
        <Header as="h2"> {t("Services at Smith PT")}</Header>
        <UserServicesSelector
          user={user}
          isEdited={true}
          setHasError={setHasError}
          services={services}
          mustUpdateSPTAUser={true}
        />
      </Container>
    </div>
  )
}
export default UserServices
