//imports
import React, { useEffect, useCallback, useState, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import useFetch from "use-http"
import dayjs from "dayjs"

//UI
import {
  Container,
  Header,
  Label,
  List,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react"
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter")
dayjs.extend(isSameOrAfter)

var isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
dayjs.extend(isSameOrBefore)

//App
function UserProtocols() {
  //hooks
  const { t } = useTranslation()
  const { get, response, loading } = useFetch()
  const { service } = useParams()

  //states
  const [protocols, setProtocols] = useState()
  const [protocolsCount, setProtocolsCount] = useState()
  const [protocolsLoading, setProtocolsLoading] = useState()

  //useeffect functions
  const fetchProtocols = useCallback(async () => {
    setProtocolsLoading(true)
    const res = await get(
      `/protocols?service=${service}&expand=exercises&sort=-startDate`
    )
    const { count, rows } = res

    if (response.ok) {
      let past = []
      let current = []
      let future = []

      rows.forEach((e) => {
        if (e.startDate && dayjs().isSameOrBefore(dayjs(e.startDate), "day")) {
          future.push(e)
        } else if (
          e.endDate &&
          dayjs().isSameOrAfter(dayjs(e.endDate), "day")
        ) {
          past.push(e)
        } else {
          current.push(e)
        }
      })
      let protocols = { past, current, future }

      setProtocols(protocols)
      setProtocolsCount(count)
      setProtocolsLoading(false)
    }
  }, [get, response, service])

  useEffect(() => {
    fetchProtocols()
  }, [fetchProtocols])
  return (
    <div className={`page user-protocols service-${service}`}>
      <Container>
        <Dimmer active={protocolsLoading} inverted>
          <Loader inverted />
        </Dimmer>
        {protocols &&
          ["current", "future", "past"].map((range) => {
            let arr = protocols[range]
            if (!arr.length) return ""
            return (
              <Fragment>
                <Header as="h2">
                  {range === "future"
                    ? t("Upcoming sessions")
                    : range === "past"
                    ? t("Past sessions")
                    : t("My exercise sessions")}
                </Header>
                <List divided selection style={{ position: "relative" }}>
                  {arr?.map((x) => (
                    <List.Item
                      as={Link}
                      key={x.id}
                      to={`/service/${service}/userprotocols/${x.id}`}
                    >
                      {x.startDate && (
                        <Label circular className="inverted">
                          {dayjs(x.startDate).format("MMM DD")}
                        </Label>
                      )}
                      {x.endDate && (
                        <Label circular>
                          {dayjs(x.endDate).format("MMM DD")}
                        </Label>
                      )}
                      <List.Content floated="right">
                        <Icon name="chevron right" />
                      </List.Content>
                      <List.Content>
                        <List.Header className="top-15">{x.name} </List.Header>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Fragment>
            )
          })}
        {!protocolsCount && !loading && (
          <Header as="h2">{t("Nothing to display yet...")}</Header>
        )}
      </Container>
    </div>
  )
}
export default UserProtocols
