//imports
import React, { Fragment, useCallback, useState, useEffect } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import useFetch from "use-http"

//UI
import {
  Card,
  List,
  Divider,
  Icon,
  Container,
  Form,
  Button,
} from "semantic-ui-react"
import { Slide } from "react-awesome-reveal"
import { toast } from "react-toastify"

//App

var localizedFormat = require("dayjs/plugin/localizedFormat")
dayjs.extend(localizedFormat)

function PatientVideos(props) {
  // local variables

  //props
  const {
    expand,
    expandBloc,
    patientId,
    isNewVideo: isNew,
    setIsNewVideo: setIsNew,
    openModal,
  } = props

  //hooks
  const { t } = useTranslation()
  const { get, put, response, loading } = useFetch()

  //states
  const [videos, setVideos] = useState([])
  const [, setVideosCount] = useState([])
  const [isEdited, setIsEdited] = useState([])
  const [comments, setComments] = useState({})

  //use effect functions
  const fetchVideos = useCallback(async () => {
    const res = await get(
      `/userMedias?patient=${patientId}&isMilestone=false&sort=-createdAt`
    )
    if (response.ok) {
      const { count, rows } = res
      setVideos(rows)
      setVideosCount(count)
    }
  }, [patientId, get, response])

  useEffect(() => {
    fetchVideos()
  }, [fetchVideos])

  //local functions

  const setIsEditedComment = (id, initialComments) => {
    setComments({ ...comments, [id]: initialComments })
    setIsEdited(id)
  }
  const onChangeComments = (params) => {
    setComments((comments) => ({ ...comments, ...params }))
  }
  const createNew = () => {
    setIsNew(true)
    expandBloc("videos")
  }

  const save = async (id) => {
    await put(`/userMedias/comments/${id}?`, {
      comments: comments[id],
    })
    if (response.ok) {
      toast.success(t("Comments succesfully saved"))
    } else {
      toast.error(t("Comments was not saved"))
    }
    setIsEdited(false)
    //    expandBloc()
    //  setIsNew(false)
  }

  const cancel = () => {
    expandBloc()
    setIsNew(false)
  }
  const toggleBlocExpand = () => {
    expand ? expandBloc() : expandBloc("videos")
    setIsNew(false)
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Card fluid>
      <Card.Content className="header-container">
        <Icon
          onClick={() => toggleBlocExpand()}
          name={expand ? "compress" : "expand"}
          style={{
            float: "right",
            cursor: "pointer",
          }}
        />
        {!isNew && (
          <Icon
            onClick={() => createNew()}
            name={"add"}
            style={{
              float: "right",
              cursor: "pointer",
              marginRight: "15px",
            }}
          />
        )}
        {isNew && (
          <Fragment>
            <Icon
              onClick={() => cancel()}
              name={"close"}
              style={{
                float: "right",
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
            <Icon
              onClick={() => save()}
              name={"save"}
              style={{
                float: "right",
                cursor: "pointer",
                marginRight: "15px",
              }}
            />
          </Fragment>
        )}
        <Card.Header
          onClick={() => toggleBlocExpand()}
          style={{ cursor: "pointer" }}
        >
          {t("Videos")}
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Meta></Card.Meta>
        <Card.Description className={!expand ? "card-container" : ""}>
          {expand !== "videos" ? (
            <List divided selection>
              {videos?.length === 0 &&
                !loading &&
                t("No video to display yet...")}
              {videos.map((x, i) => (
                <List.Item
                  key={(x, i)}
                  onClick={() =>
                    openModal({
                      header: x.title,
                      content: (
                        <Fragment>
                          {x.files?.map((v, i) => (
                            <div className="video" key={i}>
                              <video
                                id="v0"
                                autobuffer="true"
                                preload="true"
                                controls
                                playsInline
                                poster={"/video-poster.png"}
                                muted
                                loop
                                className="video-player"
                              >
                                <source src={v + "#t=0.1"} />
                              </video>
                            </div>
                          ))}
                          <Divider />
                          <h4>
                            {t("{{therapist}} commented :", {
                              therapist: x.therapists[0].firstname,
                            })}
                          </h4>
                          {x.comments}
                        </Fragment>
                      ),
                    })
                  }
                >
                  <List.Header>
                    {x.title} - {dayjs(x.createdAt).format("ll")}{" "}
                  </List.Header>

                  <List.Content className="content">{x.comments}</List.Content>
                </List.Item>
              ))}
            </List>
          ) : (
            <Card.Group>
              {isNew && (
                <div style={{ overflow: "hidden" }}>
                  <Slide direction="left">
                    <Card className="white-card  progress-photos new-form-card">
                      <Card.Content
                        style={{
                          paddingBottom: 0,
                        }}
                      >
                        <Card.Header>
                          <Form>
                            <Form.Group widths="equal">
                              <Form.Input
                                fluid
                                label="Title"
                                placeholder="Title"
                              />
                            </Form.Group>
                          </Form>
                        </Card.Header>
                        <Card.Description></Card.Description>
                      </Card.Content>
                      <Card.Content>
                        <Container textAlign="center">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <Icon name="cloud upload" size="massive" />
                            <p>{t("Click to select your photos")}</p>
                          </div>
                          <ul style={{ listStyleType: "none" }}>{files}</ul>
                        </Container>
                      </Card.Content>

                      <Card.Content>
                        <Form>
                          <Form.TextArea
                            label="Comments"
                            rows={10}
                            placeholder=""
                          />
                        </Form>
                      </Card.Content>
                    </Card>
                  </Slide>
                </div>
              )}
              {videos?.length === 0 &&
                !loading &&
                t("No video to display yet...")}
              {videos.map((x, i) => (
                <Card className="white-card" key={i}>
                  <Card.Content
                    style={{
                      paddingBottom: 0,
                    }}
                  >
                    <Card.Header>{x.title}</Card.Header>
                    <Card.Meta>
                      <span className="date">
                        {dayjs(x.createdAt).format("ll")}
                      </span>
                    </Card.Meta>
                  </Card.Content>

                  <Card.Content style={{ flexGrow: 0 }}>
                    {x.files?.map((v, i) => (
                      <div key={i}>
                        <div className="video">
                          <video
                            id="v0"
                            autobuffer="true"
                            preload="true"
                            controls
                            playsInline
                            poster={"/video-poster.png"}
                            muted
                            loop
                            className="video-player"
                          >
                            <source
                              //  type="video/mp4"
                              src={v + "#t=0.1"}
                            />
                          </video>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <a href={v}>
                            {t("Direct link (hudl...)")}{" "}
                            <Icon name="download" color="grey" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </Card.Content>

                  <Card.Content>
                    <Form>
                      <Button.Group
                        basic
                        size="small"
                        style={{
                          float: "right",
                          marginLeft: 5,
                        }}
                      >
                        <Button
                          icon={isEdited === x.id ? "save" : "edit"}
                          onClick={() =>
                            isEdited === x.id
                              ? save(x.id)
                              : setIsEditedComment(x.id, x.comments)
                          }
                        />
                        {isEdited === x.id && (
                          <Button
                            icon="close"
                            onClick={() => setIsEdited(false)}
                          />
                        )}
                      </Button.Group>

                      {isEdited === x.id && (
                        <Form.TextArea
                          label="Comments"
                          rows={10}
                          placeholder=""
                          value={comments[x.id]}
                          onChange={(e) =>
                            onChangeComments({ [x.id]: e.target.value })
                          }
                        />
                      )}
                    </Form>
                    {isEdited !== x.id && (comments[x.id] || x.comments) && (
                      <Card.Description>
                        <h4>
                          {t("{{therapist}} commented :", {
                            therapist: x.therapists[0].firstname,
                          })}
                        </h4>
                        {comments[x.id] || x.comments}
                      </Card.Description>
                    )}
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default PatientVideos
