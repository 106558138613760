//imports
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useFormState } from "react-use-form-state"
import useFetch from "use-http"

//UI
import { Message, Button, Form } from "semantic-ui-react"
import { toast } from "react-toastify"

function PatientNotes(props) {
  // props
  const { note, patientId, cancel, postSave } = props

  //hooks
  const { t } = useTranslation()
  const [formState, { textarea }] = useFormState(note)

  const { put, post, response, error } = useFetch()

  //states
  const [hasError, setHasError] = useState(false)

  //use effect functions
  useEffect(() => {
    if (note && note.comments !== formState.values.comments) {
      formState.setField("comments", note.comments)
    }
  }, [note, formState])

  //local functions
  const save = async () => {
    if (!formState.values.comments) {
      setHasError(t("Please enter your note "))
      return
    }

    var payload = { ...formState.values, user: patientId }
    if (note) {
      await put(`/patientNotes/${note.id}`, payload)
    } else {
      await post("/patientNotes", payload)
    }
    if (response.ok) {
      formState.clear()
      toast.success(t("Note succesfully saved"))
      postSave()
    } else {
      toast.error(t("Note was not saved"))
    }
  }

  return (
    <Form>
      <Button.Group
        basic
        size="small"
        style={{
          float: "right",
          marginLeft: 5,
        }}
      >
        <Button icon="close" onClick={() => cancel()} />
        <Button icon="save" onClick={() => save()} />
      </Button.Group>
      {(error || hasError) && (
        <Message negative>
          <p>
            {response.data?.message}
            {hasError}
          </p>
        </Message>
      )}
      {note?.comments}
      <Form.TextArea
        label="Note"
        placeholder="Note"
        {...textarea("comments")}
        rows={10}
      />
    </Form>
  )
}

export default PatientNotes
